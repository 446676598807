/* global document */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

// import { getAppLoads } from '../../../redux/selectors';
import Num from './Num';


class Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.initValue || props.value,
    };
  }

  componentWillMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillReceiveProps(next) {
    const { value } = this.props;

    if (next.value !== value) {
      this.setState({ value: next.value });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  handleKeyPress = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter' ) {
      const { onEnter } = this.props;

      if (onEnter) {
        onEnter();
      }

      return;
    }

    if (Number(e.key) >=0 && Number(e.key) <=9 ) {

    let eKey = String(e.key);
      this.numpad(eKey);
      return;
    }

    if (e.code === 'Escape') {
      this.reset();
      return;
    }

    if (e.code === 'Backspace') {
      this.backspace();
      return;
    }

    if (e.shiftKey || e.ctrlKey || e.metaKey || e.altKey) {
      return;
    }

    if (!/^Digit\d$/.test(e.code)) {
      return;
    }

    const value = parseInt(e.key, 10);

    if (Number.isNaN(value)) {
      return;
    }

    this.add(value);
  };

  reset = () => {
    this.setState({
      value: '',
    }, () => {
      const { onPress } = this.props;
      onPress(this.state.value, '_reset');
    });
  };

  numpad = (eKey) => {
    const { value } = this.state;

    this.setState({
      value: `${value}${eKey}`,
    }, () => {
      const { onPress } = this.props;
      onPress(this.state.value);
    });
  };

  add = (valueToAdd) => {
    const { maxLength } = this.props;
    const { value } = this.state;

    if (maxLength && value.length >= maxLength) {
      return;
    }

    this.setState({
      value: `${value}${valueToAdd}`,
    }, () => {
      const { onPress } = this.props;
      onPress(this.state.value, valueToAdd);
    });
  };

  backspace = () => {
    const { value } = this.state;

    this.setState({
      value: value.substr(0, value.length - 1),
    }, () => {
      const { onPress } = this.props;
      onPress(this.state.value, '_backspace');
    });
  };

  render() {
    const { minLength, onEnter, loads } = this.props;
    const { value } = this.state;

    const isAbleToEnter = value.length >= (minLength || 0);
    let enterButtonContent = null;

    if (onEnter) {
      if (loads > 0) {
        enterButtonContent = <CircularProgress size={60} style={{ color: 'white' }} />;
      } else {
        enterButtonContent = (
          <Icon
            style={{
              fontSize: 80,
              color: isAbleToEnter ? 'white' : 'rgba(255, 255, 255, 0.4)',
            }}
          >
            keyboard_return
          </Icon>
        );
      }
    }

    let enter = null;

    if (onEnter) {
      enter = (
        <Grid
          item
          style={{
            width: 'calc(100% / 3)',
            height: '25%',
            backgroundColor: '#FF9800',
          }}
        >
          <Button
            variant="text"
            onClick={() => {
              if (!isAbleToEnter || loads > 0) {
                return;
              }

              onEnter();
            }}
            fullWidth
            disabled={!isAbleToEnter || loads > 0}
            style={{
              height: '100%',
            }}
          >
            {enterButtonContent}
          </Button>
        </Grid>
      );
    }

    return (
      <Grid
        item
        xs
        container
        direction="row"
        wrap="wrap"
        style={{
          backgroundColor: '#607D8B',
        }}
      >
        <Num
          value={1}
          onPress={this.add}
        />
        <Num
          value={2}
          onPress={this.add}
        />
        <Num
          value={3}
          onPress={this.add}
        />
        <Num
          value={4}
          onPress={this.add}
        />
        <Num
          value={5}
          onPress={this.add}
        />
        <Num
          value={6}
          onPress={this.add}
        />
        <Num
          value={7}
          onPress={this.add}
        />
        <Num
          value={8}
          onPress={this.add}
        />
        <Num
          value={9}
          onPress={this.add}
        />

        <Grid
          item
          style={{
            width: 'calc(100% / 3)',
            height: '25%',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
        >
          <Button
            variant="text"
            onClick={this.backspace}
            fullWidth
            disabled={value.length === 0}
            style={{
              height: '100%',
              color: value.length === 0 ? '#9E9E9E' : 'rgba(255, 255, 255, 0.9)',
            }}
          >
            <Icon style={{ fontSize: 45 }}>backspace</Icon>
          </Button>
        </Grid>

        <Num
          value={0}
          onPress={this.add}
          width="34%"
        />

        {enter}
      </Grid>
    );
  }
}

Component.defaultProps = {
  loads: 0,
  initValue: null,
  value: '',
  minLength: null,
  maxLength: null,
  onEnter: null,
};

Component.propTypes = {
  onPress: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  loads: PropTypes.number,
  initValue: PropTypes.string,
  value: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
};

export default connect((state) => ({
  loads: state.app.get('loads'),
}))(Component);
