import { List, Map } from 'immutable';
import { formatPoints } from '../../libs';
import {
  USER_RESET,
  USER_SET_PHONE,
  USER_SET_CARD_NUMBER,
  USER_SET_POINTS,
  USER_SET_PURCHASED,
  USER_SET_AVAILABLE_COUPONS,
  USER_SET_INFO,
  USER_SET_IS_PHONE_CONFIRMED,
  USER_SET_ID,
} from '../actionTypes';

const defaultUser = Map({
  id: null,
  phone: null,
  isPhoneConfirmed: false,
  cardNumber: null,
  userInfo: {},
  points: 0,
  pointsFormatted: '0',
  purchased: List([]),
  availableCoupons: List([]),
});

export default function (state = defaultUser, action) {
  switch (action.type) {
    case USER_RESET: {
      return defaultUser;
    }

    case USER_SET_IS_PHONE_CONFIRMED: {
      return state.set('isPhoneConfirmed', action.value);
    }

    case USER_SET_PHONE: {
      return state.set('phone', action.phone);
    }

    case USER_SET_CARD_NUMBER: {
      return state.set('cardNumber', action.cardNumber);
    }

    case USER_SET_POINTS: {
      return state.set('points', action.points).set(
        'pointsFormatted',
        formatPoints(action.points),
      );
    }

    case USER_SET_PURCHASED: {
      return state.set('purchased', List(action.list || []));
    }

    case USER_SET_AVAILABLE_COUPONS: {
      return state.set('availableCoupons', List(action.list || []));
    }

    case USER_SET_INFO: {
      return state.set('userInfo', action.userInfo);
    }

    case USER_SET_ID: {
      return state.set('userId', action.userId).set('userSecretKey', action.userSecretKey);
    }

    default:
      return state;
  }
}
