import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { userSetPoints, appSetCoupon } from '../../redux/actions';
import CouponList from './Coupons/List';
import {trackEvent} from "../../libs";
import {buyCoupon, getUser} from "../../api";

class Component extends React.Component {
  state = { selected: false };

  onPress = (coupon) => {
    if (this.props.loads === 0 && !this.state.selected) {
      this.setState({
        selected: true,
        couponId: coupon.id,
      }, () => this.buy(coupon));
    }
  };

  buy = (coupon) => {
    const {
      loads,
      cardNumber,
      userId, userSecretKey,
    } = this.props;

    if (loads > 0) {
      return;
    }

    const { name, mindboxId } = coupon;

    if (!name) {
      trackEvent('Coupon Purchase Error', '(no name)');
      return;
    }

    // getUser({
    //   discountCard: cardNumber,
    //   successCallback: (res) => {
    //     console.log("res", res);
    //   }
    // });

    getUser({
      discountCard: cardNumber,
      successCallback: (response) => {
        buyCoupon({
          userId: response.userData.userId,
          userSecretKey: response.userData.secretKey,
          systemName: name,
          discountCard: cardNumber,
          balanceCallback: (response) => {
            this.props.userSetPoints(response.available);
          },
          boughtCouponCallback: (boughtCoupon) => {
            trackEvent('Coupon Has Been Bought', name);

            this.props.appSetCoupon(boughtCoupon.info);

            this.props.history.push({pathname: 'coupon', state: {
                coupon: {
                  ...boughtCoupon,
                  type: 'buy_first',
                },
              }});
          }, errorCallback: (response) => {

            this.setState({
              selected: false,
              couponId: undefined,
            })

            trackEvent('Coupon Purchase Error', name);

            if (response && response.success === false && response.message) {
              alert(response.message);
            }
            else {
              alert('Пожалуйста, повторите попытку еще раз');
            }

          }
        });
      }
    })
  };

  render() {
    const { list, points } = this.props;

    return (
      <CouponList
        onPress={this.onPress}
        history={this.props.history}
        selectedCouponId={this.state.couponId}
        data={list.toArray().filter(item => item.points <= points)}
      />
    );
  }
}

Component.propTypes = {
  points: PropTypes.number,
  list: PropTypes.objectOf(List),
  loads: PropTypes.number,
  cardNumber: PropTypes.string.isRequired,
  userSetPoints: PropTypes.func,
};

export default connect(state => ({
  loads: state.app.get('loads'),
  points: state.user.get('points'),
  list: state.user.get('availableCoupons'),
  cardNumber: state.user.get('cardNumber'),
  userSecretKey: state.user.get('userSecretKey'),
  userId: state.user.get('userId'),
}), { userSetPoints, appSetCoupon })(Component);
