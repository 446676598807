// import { NavigationActions } from 'react-navigation';
// import { AsyncStorage, Dimensions, PixelRatio, Alert } from 'react-native';
// import Analytics from 'appcenter-analytics';
// import KeepAwake from 'react-native-keep-awake';
import numeral from 'numeral';
// import {excludeFromStockBtn} from "./settings";


export const stores = [
  { city: 1, id: 1, title: 'ОБИ Коммунарка' },
  { city: 1, id: 2, title: 'ОБИ Химки' },
  { city: 1, id: 6, title: 'ОБИ Боровка' },
  { city: 1, id: 9, title: 'ОБИ Варшавка' },
  { city: 1, id: 13, title: 'ОБИ Белая Дача' },
  { city: 1, id: 35, title: 'ОБИ Митино' },
  { city: 1, id: 37, title: 'ОБИ Авиапарк' },
  { city: 1, id: 39, title: 'ОБИ Новая Рига' },
  { city: 1, id: 46, title: 'ОБИ Три кита' },
  { city: 1, id: 45, title: 'ОБИ Ступино' },
  { city: 1, id: 131, title: 'Оби Филион' },

  { spacer: true },

  { city: 2, id: 5, title: 'ОБИ Космос' },
  { city: 2, id: 7, title: 'ОБИ Пулково' },
  { city: 2, id: 14, title: 'ОБИ Дыбенко' },
  { city: 2, id: 15, title: 'ОБИ Парнас' },
  { city: 2, id: 16, title: 'ОБИ Лахта' },

  { spacer: true },

  { city: 3, id: 11, title: 'ОБИ Федяково' },
  { city: 3, id: 17, title: 'ОБИ Родионова' },

  { spacer: true },

  { city: 9, id: 12, title: 'ОБИ Металлургов' },
  { city: 9, id: 19, title: 'ОБИ Карнавал' },

  { spacer: true },

  { city: 5, id: 23, title: 'ОБИ Волгоград' },
  { city: 14, id: 44, title: 'ОБИ Волжский' },


  { spacer: true },

  { city: 8, id: 108, title: 'ОБИ Казань' },
  { city: 7, id: 30, title: 'ОБИ Краснодар' },
  { city: 4, id: 33, title: 'ОБИ Рязань' },
  { city: 6, id: 34, title: 'ОБИ Саратов' },
  { city: 12, id: 42, title: 'ОБИ Брянск' },
  { city: 13, id: 43, title: 'ОБИ Тула' },
];

export const storesWithoutExcludeFromStock = [36];

numeral.localeData().delimiters.thousands = ' ';
// numeral.register('locale', 'ru', {
//   delimiters: {
//     thousands: ' ',
//     decimal: ',',
//   },
//   abbreviations: {
//     thousand: 'тыс',
//     million: 'млн',
//     billion: 'млдр',
//     trillion: 'тр',
//   },
//   currency: {
//     symbol: '₽',
//   },
// });


// export const gotoNewUserScreen = (props) => {
//   props.navigation.dispatch(NavigationActions.reset({
//     index: 0,
//     actions: [
//       NavigationActions.navigate({ routeName: 'user' }),
//     ],
//   }));
// };
//
// export const gotoDashboard = (props) => {
//   props.navigation.dispatch(NavigationActions.reset({
//     index: 1,
//     actions: [
//       NavigationActions.navigate({ routeName: 'user' }),
//       NavigationActions.navigate({ routeName: 'dashboard' }),
//     ],
//   }));
// };


// const { width: SCREEN_WIDTH } = Dimensions.get('window');
//
// // based on iphone 5s's scale
// const scale = SCREEN_WIDTH / 320;
//
// export const normalize = (_size) => {
//   const size = Math.round(PixelRatio.roundToNearestPixel(_size));
//   return Platform.OS === 'ios' ? size : size - 2;
// };

// const { width, height } = Dimensions.get('window');

// Guideline sizes are based on standard ~5" screen mobile device
// const guidelineBaseWidth = 350;
// const guidelineBaseHeight = 680;

// const scale = size =>
//   PixelRatio.roundToNearestPixel((width / guidelineBaseWidth) * size);
//
// const verticalScale = size =>
//   PixelRatio.roundToNearestPixel((height / guidelineBaseHeight) * size);
//
// const moderateScale = (size, factor = 0.5) =>
//   PixelRatio.roundToNearestPixel(size + ((scale(size) - size) * factor));

// export { scale, verticalScale, moderateScale };

// const stores = [
//   {
//     id: 23,
//     title: 'ОБИ Волгоград',
//   },
//   {
//     id: 44,
//     title: 'ОБИ Волжский',
//   },
//   {
//     id: 5,
//     title: 'ОБИ Космос',
//   },
//   {
//     id: 7,
//     title: 'ОБИ Пулково',
//   },
//   {
//     id: 14,
//     title: 'ОБИ Дыбенко',
//   },
//   {
//     id: 15,
//     title: 'ОБИ Парнас',
//   },
//   {
//     id: 16,
//     title: 'ОБИ Лахта',
//   },
// ];

// export const getStoreById = id => stores.find(item => item.id === id);

export const getRegisterId = (storeId, registerNumber) => {
  if (!storeId || !registerNumber) {
    return null;
  }

  return `${storeId}_${registerNumber}`;
};

export const formatPoints = points => numeral(points).format('0,0');

export const formatPhone = (phone) => {
  const m = /^(\+\d)([\d]{3})([\d]{3})([\d]{2})([\d]{2})$/.exec(phone);

  if (m) {
    return `${m[1]} (${m[2]}) ${m[3]}-${m[4]}-${m[5]}`;
  }

  return phone;
};

export const setOperatorAuthorized = (isAuthorized) => {
  localStorage.setItem('@OBICashBox:operatorAuthorized', isAuthorized ? '1' : '0');
};

export const getOperatorAuthorized = (successCallback) => {
  const authorized = localStorage.getItem('@OBICashBox:operatorAuthorized');

  if (authorized) {
    successCallback(parseInt(authorized, 10) === 1);
  }
};

export const setOperatorStore = (store) => {
  localStorage.setItem('@OBICashBox:operatorStore', store.toString());
};

export const getOperatorStore = (successCallback) => {
  const operatorStore = localStorage.getItem('@OBICashBox:operatorStore');

  if (operatorStore) {
    successCallback(parseInt(operatorStore, 10));
  }
};

export const setOperatorRegister = (store) => {
  localStorage.setItem('@OBICashBox:operatorRegister', store.toString());
};

export const getOperatorRegister = (successCallback) => {
  const operatorRegister = localStorage.getItem('@OBICashBox:operatorRegister');

  if (operatorRegister) {
    successCallback(operatorRegister);
  }
};

export const resetOperator = () => {
  localStorage.removeItem('@OBICashBox:operatorAuthorized');
  localStorage.removeItem('@OBICashBox:operatorStore');
  localStorage.removeItem('@OBICashBox:operatorRegister');
};

//
// export const alert = (message, title = 'Внимание') => {
//   Alert.alert(title, message);
// };

// export const changeKeepAwake = (shouldBeAwake) => {
//   if (shouldBeAwake) {
//     KeepAwake.activate();
//   } else {
//     KeepAwake.deactivate();
//   }
// };

export const formatCardNumber = (_number) => {
  const number = _number.split('');
  const length = _number.length / 3;
  let formatted = '';

  for (let i = 0; i < length; i += 1) {
    for (let j = 0; j < 3; j += 1) {
      const numb = number[(i * 3) + j];

      if (numb && numb !== ' ') {
        formatted += numb;
      }
    }

    if (i !== 5) {
      formatted += ' ';
    }
  }

  return formatted;
};

export const trackEvent = (name, value, props = {}) => {
  const properties = {
    ...props,
  };

  if (value) {
    properties.value = value;
  }

  // console.log('track', {name, properties})

  // Analytics.trackEvent(name, properties);
};

export const pad = (val, len = 2) => {
  let valS = String(val);
  while (valS.length < len) valS = `0${valS}`;
  return valS;
};
