import { Map } from 'immutable';
import {
  APP_SET_IP,
  APP_ADD_LOAD,
  APP_REMOVE_LOAD,
  APP_SET_PHONE,
  APP_SET_CARD_NUMBER,
  APP_USER_RESET,
  APP_SET_COUPON,
} from '../actionTypes';

const defaultAppPhone = process.env.NODE_ENV === 'development' ? '9653619088' : null;
const defaultAppCardNumber = process.env.NODE_ENV === 'development' ? '224675019' : null; // '200101648' // 200784209 //221986583

const initialState = Map({
  ip: null,
  loads: 0,
  phone: defaultAppPhone,
  cardNumber: defaultAppCardNumber,
  coupon: Map(),
});


export default function (state = initialState, action) {
  switch (action.type) {
    case APP_SET_IP:
      return state.set('ip', action.ip);

    case APP_ADD_LOAD:
      return state.set('loads', state.get('loads') + 1);

    case APP_REMOVE_LOAD: {
      const load = state.get('loads') - 1;
      return state.set('loads', load < 0 ? 0 : load);
    }


    case APP_USER_RESET: {
      return state.set(
        'phone',
        defaultAppPhone,
      ).set(
        'cardNumber',
        defaultAppCardNumber,
      );
    }

    case APP_SET_PHONE: {
      return state.set('phone', action.phone);
    }

    case APP_SET_CARD_NUMBER: {
      return state.set('cardNumber', action.cardNumber);
    }

    case APP_SET_COUPON:
      return state.set('coupon', Map(action.coupon));

    default:
      return state;
  }
}
