import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Component = props => (
  <Grid
    style={{
      marginRight: 18,
      flexDirection: 'row',
      alignItems: 'center',
    }}
  >
    <span
      style={{
        fontSize: 14,
        color: '#aaa',
      }}
    >
      Баланс:
    </span>
    <span
      style={{
        marginLeft: 7,
        fontSize: 16,
        fontWeight: '600',
        color: '#333',
      }}
    >
      { props.pointsFormatted }
    </span>
  </Grid>
);

Component.propTypes = {
  pointsFormatted: PropTypes.string.isRequired,
};

export default connect(state => ({
  pointsFormatted: state.user.get('pointsFormatted'),
}))(Component);
