import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button/Button";
import {formatPoints} from "../../../libs";

const styles = () => ({
  buttonLabel: {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '56px 16px 8px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
  }
});

const Component = props => (
  <Grid
    item
    style={{
      width: '20%',
      height: 200,
      flexDirection: 'row',
    }}
  >
    <Button
      variant="text"
      fullWidth
      style={{
        height: '100%',
        borderRadius: 0,
        background: '#607D8B',
        textTransform: 'none',
        textAlign: 'left',
        border: '1px solid #678695',
        opacity: props.loads === 0 ? 1 : .9,
      }}
      disabled={props.loads > 0}
      onClick={() =>
        props.onPress({
          id: props.id,
          type: props.type,
          mindboxId: props.mindboxId || null,
          number: props.number || null,
          name: props.name,
        })
      }
      classes={{label: props.classes.buttonLabel}}
    >
      <Grid
        container
        direction="column"
      >
        {props.type && props.type === 'Online' &&
          <Grid
            item
            style={{
              fontSize: 16,
              fontWeight: '400',
              marginBottom: 16,
              color: 'orange',
            }}
          >
            Онлайн купон
          </Grid>
        }
        <Grid
          item
          style={{
            fontSize: 16,
            fontWeight: '400',
            color: 'white',
            marginBottom: 16,
          }}
        >
          { props.category || props.title }
        </Grid>
        <Grid
          item
          style={{
            fontSize: 16,
            fontWeight: '600',
            color: 'gold',
            marginBottom: 16,
          }}
        >
          {formatPoints(props.price)} <i className="rouble"/>
        </Grid>
        {props.min && props.type !== 'Online' &&
          <Grid
            item
            style={{
              fontSize: 14,
              fontWeight: '400',
              color: 'white',
            }}>
            Покупка от <span style={{ color: 'black' }}>{formatPoints(props.min)}{' '} <i className="rouble"/></span>
          </Grid>
        }
      </Grid>
    </Button>
  </Grid>
);

Component.propTypes = {
  onPress: PropTypes.func.isRequired,
  loads: PropTypes.number,
  id: PropTypes.string,
  type: PropTypes.string,
  mindboxId: PropTypes.number,
  number: PropTypes.string,
  name: PropTypes.string.isRequired,
  category: PropTypes.string,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  isUserCoupon: PropTypes.bool,
  min: PropTypes.number,
  selected: PropTypes.bool,
};

export default connect(state => ({
  loads: state.app.get('loads'),
}))(withStyles(styles)(Component));
