import createHistory from 'history/createBrowserHistory';

export const defaultStyles = {
  root: {
    flexGrow: 1,
    position: 'relative',
    height: 630,
    width: 1200,
    margin: 'auto',
    backgroundColor: '#c8d4d9',
  },
  container: {
    position: 'relative',
    height: 600,
    marginTop: 30,
  },
};

export const api = {
  test: 'https://master-obiclub.review.bstd.ru/',
  prod: 'https://obiclub.ru',
};

export const history = createHistory();

export const excludeFromStockBtn = false;
export const showAutumnCashback = false;
