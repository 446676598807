import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


const Component = class extends React.PureComponent {
  render() {
    const { value, onPress } = this.props;

    return (
      <Grid
        item
        style={{
          width: 'calc(100% / 3)',
          height: '25%',
        }}
      >
        <Button
          onClick={() => onPress(value)}
          fullWidth
          style={{
            height: '100%',
            fontSize: 80,
            fontWeight: 300,
            color: 'white',
            borderRadius: 0,
          }}
        >
          {value}
        </Button>
      </Grid>
    );
  }
};

Component.propTypes = {
  onPress: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default Component;
