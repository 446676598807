import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Coupon from "./Coupon";
import { defaultStyles } from "../../../settings";
import Header from "../Header/Header";
import Points from "../Header/Points";
import Fade from "@material-ui/core/Fade/Fade";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";


const styles = () => ({
  root: {...defaultStyles.root},
  container: {...defaultStyles.container},
  fadeOut: {
    transition: 'opacity .2s linear',
    opacity: 0,
  },
});

class Component extends React.Component {
  onPress = (coupon) => {
    this.props.onPress(coupon);
  };

  render() {
    const { classes, data, isUserCoupon } = this.props;

    return (
      <Grid
        className={classes.root}
        container
        direction="row"
        alignItems="stretch"
        style={{ overflowY: !this.props.selectedCouponId ? 'auto' : 'hidden' }}
      >
        <Header title={isUserCoupon ? 'Купленные купоны' : 'Списать бонусы'} backButton="dashboard">
          <Points />
        </Header>

          <Grid
            className={classes.container}
            container
            alignContent={this.props.selectedCouponId ? 'align-center' : 'flex-start'}
          >
            <Fade
              in={this.props.selectedCouponId}
              style={{
                position: 'absolute',
                width: 100,
                height: 100,
                left: 'calc(50% - 50px)',
                top: 'calc(50% - 50px)',
              }}
              timeout={{
                exit: 10000,
              }}
              unmountOnExit
            >
              <CircularProgress
                style={{color: '#FF9800'}}
                size={60}
              />
            </Fade>
            <Grid
              container
              alignContent="flex-start"
              className={this.props.selectedCouponId && classes.fadeOut}
            >
              {data.map((coupon, key) => (
                <Coupon
                  {...coupon}
                  history={this.props.history}
                  selected={coupon.id === this.props.selectedCouponId}
                  key={key}
                  onPress={this.onPress}
                  price={coupon.points}
                  isUserCoupon={isUserCoupon}
                />
              ))}
            </Grid>
          </Grid>
      </Grid>
    );
  }
}

Component.propTypes = {
  onPress: PropTypes.func.isRequired,
  isUserCoupon: PropTypes.bool,
  selectedCouponId: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
};

export default (withStyles(styles)(Component));
