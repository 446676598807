export const OPERATOR_RESET = 'OPERATOR_RESET';
export const OPERATOR_SET_AUTHORIZED = 'OPERATOR_SET_AUTHORIZED';
export const OPERATOR_SET_STORE_ID = 'OPERATOR_SET_STORE_ID';
export const OPERATOR_SET_REGISTER = 'OPERATOR_SET_REGISTER';

export const APP_SET_IP = 'APP_SET_IP';
export const APP_ADD_LOAD = 'APP_ADD_LOAD';
export const APP_REMOVE_LOAD = 'APP_REMOVE_LOAD';

export const APP_USER_RESET = 'APP_USER_RESET';
export const APP_SET_PHONE = 'APP_SET_PHONE';
export const APP_SET_CARD_NUMBER = 'APP_SET_CARD_NUMBER';
export const APP_SET_COUPON = 'APP_SET_COUPON';

export const USER_RESET = 'USER_RESET';
export const USER_SET_PHONE = 'USER_SET_PHONE';
export const USER_SET_CARD_NUMBER = 'USER_SET_CARD_NUMBER';
export const USER_SET_POINTS = 'USER_SET_POINTS';
export const USER_SET_PURCHASED = 'USER_SET_PURCHASED';
export const USER_SET_AVAILABLE_COUPONS = 'USER_SET_AVAILABLE_COUPONS';
export const USER_SET_INFO = 'USER_SET_INFO';

export const USER_SET_IS_PHONE_CONFIRMED = 'USER_SET_IS_PHONE_CONFIRMED';
export const USER_SET_ID = 'USER_SET_ID';

export const API_SET_PING = 'API_SET_PING';
