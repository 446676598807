import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { operatorSetStoreId } from '../../redux/actions';
import { defaultStyles } from "../../settings";
import {stores, trackEvent, setOperatorStore} from "../../libs";

const styles = () => ({
  root: { ...defaultStyles.root, alignItems: 'center' },
  cities: { width: 1000, padding: '40px 0' },
  store: { height: 38 },
  button: {
    textTransform: 'uppercase',
    width: 320,
  },
  spacer: {
    margin: '10px 0'
  }
});

class Component extends React.Component {
  selectStore = (id) => {
    this.props.operatorSetStoreId(id);
    trackEvent('Select Store', stores.find(i => i.id === id).title);
    setOperatorStore(id);
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid
        className={classes.root}
        container
        direction="column"
      >
        <Grid
          xs
          item
          direction="row"
          // alignItems="center"
          container
          className={classes.cities}
          style={{
            flexBasis: 'unset'
          }}
        >
          {stores.map((store, index) => {
            if (store.spacer) {
              return <Grid
                item
                xs={12}
                key={index + 1000}
                className={classes.spacer}
              />
            }

            return <Grid
              container
              item
              xs={3}
              key={store.id}
              className={classes.store}
              justify="center"
              alignItems="center"
              style={{
                padding: '0 3px'
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.selectStore(store.id)}
                className={classes.button}
              >
                {store.title}
              </Button>
            </Grid>
          })}
        </Grid>
      </Grid>
    );
  }
}

Component.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.any.isRequired,
    cities: PropTypes.any,
    store: PropTypes.any,
    storeTitle: PropTypes.any,
    button: PropTypes.any,
  }).isRequired,

  operatorSetStoreId: PropTypes.func.isRequired,
};

export default connect(null, { operatorSetStoreId })(withStyles(styles)(Component));
