import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { history } from "../../../settings";
import { stores, resetOperator } from "../../../libs";
import Icon from '@material-ui/core/Icon';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiSetPing, operatorReset, userReset } from "../../../redux/actions";

const styles = () => ({
  header: {
    padding: '3px 0',
    height: 30,
    backgroundColor: '#f0f0f0',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  itemWidth: {
    width: 'calc(100% / 3)',
    alignItems: 'center',
    display: 'flex'
  },
  textWidth: {
    fontSize: '13px',
    marginTop: '0px'
  },
  button: {
    minWidth: 54,
    minHeight: 30,
    padding: 0,
    borderRadius: 0,
  },
  icon: {
    color: '#333',
    margin: '3px 5px',
  },
});

class Component extends React.Component {
  goto = () => {
    history.push(this.props.backButton);
  };

  logout = () => {
    resetOperator();
    history.replace('/');
    this.props.userReset();
    this.props.operatorReset();
    this.props.apiSetPing(false);

    window.pingInterval = clearInterval(window.pingInterval);
  };

  render() {
    const { classes, title, storeId, register, children, backButton, loading } = this.props;
    const store = storeId && stores.find(i => i.id === storeId);

    return (
      <Grid
        className={classes.header}
        container
        direction="row"
        alignItems="center"
      >
        <Grid
          className={classes.itemWidth}
          style={{ textAlign: 'left' }}
        >

          {backButton && <Button onClick={this.goto} className={classes.button}>
            <Icon size={30} className={classes.icon}>arrow_back</Icon>
          </Button>}

          <Button onClick={this.logout} className={classes.button}>
            <Icon size={30} className={classes.icon}>lock_open</Icon>
          </Button>

          <span
            style={{
              fontSize: '13px',
              margin: '0 5px',
            }}
          >
            {store ? <>{store.title}&nbsp;</> : ''}
            {register ? <>№ {register}&nbsp;</> : ''}
            {/*<span style={{color: 'rgb(170, 170, 170'}}>1.0.44</span>*/}
          </span>
          <Fade
            in={loading}
            unmountOnExit
          >
            <CircularProgress
              style={{color: '#FF9800', margin: '2px 5px'}}
              size={20}
            />
          </Fade>
        </Grid>
        <Grid
          className={classes.itemWidth}
          style={{
            justifyContent: 'center',
            textTransform: 'uppercase',
            fontWeight: 'bold'
          }}
        >
          <span className={classes.textWidth}>{title}</span>
        </Grid>
        <Grid
          className={classes.itemWidth}
          container
          style={{
            justifyContent: 'flex-end',
            fontSize: 0,
          }}
        >
          {children}
        </Grid>
      </Grid>
    );
  }
}

Component.propTypes = {
  title: PropTypes.string,
  backButton: PropTypes.string,
  storeId: PropTypes.number,
  register: PropTypes.string,
  children: PropTypes.any,
  apiSetPing: PropTypes.func,
  operatorReset: PropTypes.func,
  userReset: PropTypes.func,
  loading: PropTypes.bool,
};


export default connect(state => ({
  storeId: state.operator.get('storeId'),
  register: state.operator.get('register'),
  loading: 0 < state.app.get('loads'),
}), {
  apiSetPing,
  operatorReset,
  userReset,
})(withStyles(styles)(Component));
