import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Keyboard from '../common/keyboard/Keyboard';
import Dot from '../common/Dot';
import Header from './Header/Header';
import {
  userSetId,
  userReset,
  userSetCardNumber,
  userSetPoints,
  userSetPhone,
  appUserReset,
  appSetCardNumber,
  appSetPhone,
  // apiSetPing,
} from '../../redux/actions';
import Icon from "@material-ui/core/Icon/Icon";
import Button from "@material-ui/core/Button/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {defaultStyles} from "../../settings";
import { trackEvent } from "../../libs";
import { getUserByCardNumber, getUserByPhone, excludePurchaseByCardName } from "../../api";
import { excludeFromStockBtn } from "../../settings";
const CARD_PREFIX = '316020020';
const PHONE_COUNTRY = '+7';

const styles = theme => ({
  root: defaultStyles.root,
  container: {...defaultStyles.container},
  dot: {
    color: theme.palette.text.disabled,
    fontSize: 24,
    margin: '0 1px',
    width: 13,
  },
  activeDot: {
    color: theme.palette.text.primary,
    fontSize: 24,
    fontWeight: 'bold',
    width: 15,
  },
  title: {
    fontSize: 16,
    fontWeight: '400',
    textTransform: 'none',
    textAlign: 'center',
  },
  buttonLabel: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
  },
});


class Component extends React.Component {
  state = {
    register: '',
    option: 'card',
    formattedCardPrefix: CARD_PREFIX,
    modalParams: null,
  };

  onPressEnter = () => {
    this.handleNumber();
  };

  setModalParams = modalParams => {
    this.setState({ modalParams });
  };

  cardErrorFunc = (
    userCard,
    response,
    errorSubject = 'Карта не найдена',
    errorMessage = 'Пожалуйста, проверьте введенный номер',
    discountCardIsInactive
  ) => {

    trackEvent('Login via Card Failed', errorSubject, {
      cardNumber: userCard,
    });

    this.setModalParams({ errorSubject, errorMessage, discountCardIsInactive });
  };

  excludePurchase = () => {
    const { card, storeId, loads } = this.props;

    if (card && !loads) {
      excludePurchaseByCardName({cardNumber: card, storeId}, () => {
        this.props.appSetCardNumber(CARD_PREFIX);
        this.setModalParams(null);
        this.props.history.push({pathname: 'success', state: {
          type: 'excludePurchase',
        }});
      }, () => {
        alert('Пожалуйста, попробуйте еще раз', 'Произошла ошибка');
      });
    }
  };

  handleNumber = (_card, _phone) => {
    let card = _card || this.props.card;
    card = card ? card.replace(new RegExp(`^${CARD_PREFIX}`), '') : '';

    let phone = _phone || this.props.phone;
    phone = phone ? phone.replace(new RegExp(`^\\${PHONE_COUNTRY}`), '') : '';

    const o = this.state.option;
    const value = o === 'card' ? card : phone;

    if (!value) {
      return;
    }

    if (o === 'card') {
      if (value.length !== 9) {
        return;
      }

      // @todo Не понятно как сбрасывать текущего пользователя при переходе с другого экрана,
      // поэтому пользователь сбрасывается при попытке авторизоваться
      this.props.userReset();

      trackEvent('Login via Card');

      const userCard = `${CARD_PREFIX}${value}`;

      this.props.userSetCardNumber(userCard);

      getUserByCardNumber(userCard, (cardResponse) => {
        trackEvent('Success Login via Card', userCard);
        this.props.userSetPoints(cardResponse.points);
        this.props.userSetId(cardResponse);
        this.goto('dashboard');
        this.props.userSetPhone(`+${cardResponse.phone}`);
      }, (response, errorSubject, errorMessage, discountCardIsInactive) => {
        //TODO корректные сообщения приходят только для 403
        if (response && response.status === 403 && response.title && response.message) {
          this.cardErrorFunc(
            userCard,
            response,
            response.title,
            response.message,
            discountCardIsInactive
          );
          return;
        }
        this.cardErrorFunc(userCard, response, errorSubject, errorMessage, discountCardIsInactive);
      });
    }

    if (o === 'phone') {
      if (value.length !== 10) {
        return;
      }

      // @todo Не понятно как сбрасывать текущего пользователя при переходе с другого экрана,
      // поэтому пользователь сбрасывается при попытке авторизоваться
      this.props.userReset();

      trackEvent('Login via Phone');

      const userPhone = `${PHONE_COUNTRY}${value}`;

      getUserByPhone(userPhone, (response) => {
        trackEvent('Success Login via Phone', userPhone);
        this.props.userSetCardNumber(response.cardNumber);
        this.props.userSetPoints(response.points);
        this.props.userSetId(response);
        this.props.userSetPhone(userPhone);
        this.goto('earn-bonuses');
      }, () => {
        trackEvent('Login via Phone Failed', userPhone);
        alert('Пожалуйста, проверьте введенный номер', 'Пользователь не найден');
      });
    }
  };


  goto(where) {
    this.props.appUserReset();
    this.props.history.push(where);
  }

  chooseCard = () => {
    this.setState({
      option: 'card',
    });
  };

  choosePhone = () => {
    this.setState({
      option: 'phone',
    });
  };

  onPress = (keyboardValue) => {
    if (this.state.option === 'card') {
      this.props.appSetCardNumber(`${CARD_PREFIX}${keyboardValue}`);
    } else {
      this.props.appSetPhone(`${PHONE_COUNTRY}${keyboardValue}`);
    }
  };

  render() {
    const { classes } = this.props;
    let { card, phone } = this.props;

    card = card ? card.replace(new RegExp(`^${CARD_PREFIX}`), '') : '';
    phone = phone ? phone.replace(new RegExp(`^\\${PHONE_COUNTRY}`), '') : '';

    const activeColor = 'white';
    const passiveColor = '#999999';
    const isCardOption = this.state.option === 'card';
    const isPhoneOption = !isCardOption;


    let isSPB;

    if (this.props.storeId < 23) {
      isSPB = true;
    }

    const { modalParams } = this.state;
    const { errorSubject, errorMessage, discountCardIsInactive } = modalParams || {};

    return (
      <Grid
        className={classes.root}
        container
        direction="row"
        alignItems="stretch"
      >
        <Header title="новый покупатель" />
        <Grid
          className={classes.container}
          container
        >
          <Grid
            container
            item
            xs={6}
            direction="column"
            alignItems="stretch"
          >
            <Button
              style={{
                height: '50%',
                borderRadius: 0,
                background: isCardOption ? '#FF9800' : '#607d8b',
              }}
              classes={{ label: classes.buttonLabel }}
              onClick={this.chooseCard}
            >
              <Grid
                item
                container
                style={{ height: '100%', padding: '30px 0' }}
                direction="column"
                alignItems="center"
              >
                <Grid
                  item
                  className={classes.title}
                  style={{color: isCardOption ? activeColor : passiveColor}}
                >
                  Введите номер {' '}
                  <span style={{fontWeight: 'bold'}}>карты</span><br/>
                  для&nbsp;использования купона
                </Grid>

                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ paddingTop: 40 }}
                >
                  <Grid
                    item
                    style={{
                      color: isCardOption ? activeColor : passiveColor,
                      fontWeight: '500',
                      fontSize: 24,
                      marginRight: 5,
                    }}
                  >
                    {this.state.formattedCardPrefix}
                  </Grid>

                  <Dot
                    classes={classes}
                    value={card[0]}
                    style={{ color: !isCardOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={card[1]}
                    style={{ color: !isCardOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={card[2]}
                    style={{ color: !isCardOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={card[3]}
                    style={{ color: !isCardOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={card[4]}
                    style={{ color: !isCardOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={card[5]}
                    style={{ color: !isCardOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={card[6]}
                    style={{ color: !isCardOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={card[7]}
                    style={{ color: !isCardOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={card[8]}
                    style={{ color: !isCardOption && passiveColor }}
                  />
                </Grid>

                <Grid
                  item
                  style={{
                    marginTop: 'auto',
                  }}
                >
                  <Icon
                    style={{
                      fontSize: 70,
                      color: isCardOption ? '#eee' : passiveColor,
                    }}
                  >
                    credit_card
                  </Icon>
                </Grid>
              </Grid>
            </Button>

            <Button
              style={{
                height: '50%',
                borderRadius: 0,
                background: isPhoneOption ? '#FF9800' : '#607d8b',
              }}
              classes={{ label: classes.buttonLabel }}
              onClick={this.choosePhone}
            >
              <Grid
                item
                container
                style={{
                  height: '100%',
                  padding: '30px 0',
                }}
                direction="column"
                alignItems="center"
              >
                <Grid
                  item
                  className={classes.title}
                  style={{color: isPhoneOption ? activeColor : passiveColor}}
                >
                  Введите номер {' '}
                  <span style={{fontWeight: 'bold'}}>телефона</span><br/>
                  для&nbsp;начисления бонусов
                </Grid>

                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ paddingTop: 40 }}
                >
                  <div
                    style={{
                      color: isPhoneOption ? activeColor : passiveColor,
                      fontWeight: '500',
                      fontSize: 24,
                      marginRight: 12,
                    }}
                  >
                    {PHONE_COUNTRY}
                  </div>

                  <Dot
                    classes={classes}
                    value={phone[0]}
                    style={{ color: !isPhoneOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={phone[1]}
                    style={{ color: !isPhoneOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={phone[2]}
                    style={{ marginRight: 12, color: !isPhoneOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={phone[3]}
                    style={{ color: !isPhoneOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={phone[4]}
                    style={{ color: !isPhoneOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={phone[5]}
                    style={{ marginRight: 12, color: !isPhoneOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={phone[6]}
                    style={{ color: !isPhoneOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={phone[7]}
                    style={{ marginRight: 12, color: !isPhoneOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={phone[8]}
                    style={{ color: !isPhoneOption && passiveColor }}
                  />
                  <Dot
                    classes={classes}
                    value={phone[9]}
                    style={{ color: !isPhoneOption && passiveColor }}
                  />
                </Grid>

                <Grid
                  item
                  style={{
                    marginTop: 'auto',
                  }}
                >
                  <Icon
                    style={{
                      fontSize: 70,
                      color: isPhoneOption ? '#eee' : passiveColor,
                    }}
                  >
                    smartphone
                  </Icon>
                </Grid>
              </Grid>
            </Button>
          </Grid>

          {isCardOption && <Keyboard
            initValue={card}
            value={card}
            onPress={this.onPress}
            onEnter={this.onPressEnter}
            minLength={9}
            maxLength={9}
          />}

          {isPhoneOption && <Keyboard
            initValue={phone}
            value={phone}
            onPress={this.onPress}
            onEnter={this.onPressEnter}
            minLength={10}
            maxLength={10}
          />}
        </Grid>

        <Dialog open={modalParams}>
          <DialogTitle id="alert-dialog-title">{errorSubject}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                {excludeFromStockBtn && isSPB && <Button onClick={this.excludePurchase} color="secondary">
                  Исключить покупку из акции
                </Button>}
                {discountCardIsInactive && <Button onClick={() => {
                  this.setModalParams(null);
                  this.props.history.push({pathname: 'enter-phone', state: {
                    type: 'card-activation',
                  }});
                }} color="primary" style={{ color: 'green' }}>
                  Активировать карту
                </Button>}
              </div>
              <Button style={{ marginLeft: 'auto' }} onClick={() => this.setModalParams(null)} color="primary">
                ОК
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  phone: PropTypes.string,
  card: PropTypes.string,

  userReset: PropTypes.func,
  userSetCardNumber: PropTypes.func,
  userSetPoints: PropTypes.func,
  userSetPhone: PropTypes.func,
  appUserReset: PropTypes.func,
  appSetCardNumber: PropTypes.func,
  storeId: PropTypes.number,
  appSetPhone: PropTypes.func,
};

export default connect(state => ({
  phone: state.app.get('phone'),
  card: state.app.get('cardNumber'),
  storeId: state.operator.get('storeId'),
  // storeId: state.operator.get('storeId'),
  // register: state.operator.get('register'),
  // apiPing: state.api.get('apiPing'),
}), {
  userReset,
  userSetCardNumber,
  userSetPoints,
  userSetPhone,
  appUserReset,
  appSetCardNumber,
  appSetPhone,
  userSetId,
  // apiSetPing,
})(withStyles(styles)(Component));
