import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import 'typeface-roboto';
import 'material-design-icons/iconfont/material-icons.css';
import './index.css';
import Password from './components/Preferences/Password';
import Store from './components/Preferences/Store';
import Register from './components/Preferences/Register';
import User from './components/App/User';
import Dashboard from './components/App/Dashboard';
import EnterCoupon from './components/App/EnterCoupon';
import EnterPhone from './components/App/EnterPhone';
import EnterPhoneConfirm from './components/App/EnterPhoneConfirm';
import UserCoupons from './components/App/UserCoupons';
import AvailableCoupons from './components/App/AvailableCoupons';
import EarnBonuses from './components/App/EarnBonuses';
import PayCoupon from './components/App/PayCoupon';
import Success from './components/App/Success';
import { history } from './settings';
import { getOperatorAuthorized, getOperatorRegister, getOperatorStore } from "./libs";
import { operatorSetAuthorized, operatorSetRegister, operatorSetStoreId } from "./redux/actions";

class App extends React.Component {
  //todo обьеденить settings libs api
  //todo ping и Error screen
  //todo sentry

  // todo modal 4erez appjs

  componentWillMount() {
    getOperatorAuthorized(isAuthorized => {
      this.props.operatorSetAuthorized(isAuthorized);
    });

    getOperatorStore(storeId => {
      this.props.operatorSetStoreId(storeId);
    });

    getOperatorRegister(register => {
      this.props.operatorSetRegister(register);
    });
  }


  render() {
    if (!this.props.authorized) {
      return <Password />;
    } else if (!this.props.storeId) {
      return <Store />;
    } else if (!this.props.register) {
      return <Register />;
    }

    return (
      <Router history={history}>
        <Switch>
          <Route component={User} path="/user" />
          {!this.props.cardNumber && <Redirect to="/user" />}
          <Route component={Dashboard} path="/dashboard" />
          <Route component={EnterCoupon} path="/enter-coupon" />
          <Route component={EnterPhone} path="/enter-phone" />
          <Route component={EnterPhoneConfirm} path="/enter-phone-confirm" />
          <Route component={UserCoupons} path="/user-coupons" />
          <Route component={AvailableCoupons} path="/available-coupons" />
          <Route component={EarnBonuses} path="/earn-bonuses" />
          <Route component={PayCoupon} path="/coupon" />
          <Route component={Success} path="/success" />
        </Switch>
      </Router>
    );
  }
}

App.propTypes = {
  operatorSetAuthorized: PropTypes.func,
  operatorSetRegister: PropTypes.func,
  operatorSetStoreId: PropTypes.func,
  cardNumber: PropTypes.string,
};

export default connect(state => ({
  authorized: state.operator.get('authorized'),
  storeId: state.operator.get('storeId'),
  register: state.operator.get('register'),
  cardNumber: state.user.get('cardNumber'),
}), {
  operatorSetAuthorized,
  operatorSetRegister,
  operatorSetStoreId,
})(App);