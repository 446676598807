import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { userSetPoints, appSetCoupon } from '../../redux/actions';
import CouponList from './Coupons/List';

class Component extends React.Component {
  state = { couponId: null };

  onPress = (coupon) => {
    const coupons = this.props.list.toJS();

    const couponData = coupons.find(i => i.mindboxId === coupon.mindboxId);

    this.props.appSetCoupon(couponData.info);

    this.props.history.push({pathname: 'coupon', state: {
      coupon: {
        ...coupon,
      },
    }});
  };

  render() {
    return (
      <CouponList
        onPress={this.onPress}
        data={this.props.list.toArray()}
        selectedCouponId={this.state.couponId}
        isUserCoupon
      />
    );
  }
}

Component.propTypes = {
  navigation: PropTypes.object.isRequired,
  list: PropTypes.objectOf(List),
};

export default connect(state => ({
  list: state.user.get('purchased'),
}), { userSetPoints, appSetCoupon })(Component);
