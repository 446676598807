import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import Header from './Header/Header';
import Points from './Header/Points';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Button from "@material-ui/core/Button/Button";
// import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { List } from 'immutable';
import {defaultStyles} from "../../settings";
import {
  getUserCoupons,
  getAvailableCoupons,
  getUserInfo,
  excludePurchaseByCardName,
  loadAutumn2020,
  sendSMSForPhoneEntrance, getUserCouponsAsync, getCategory,
} from '../../api';
import {formatPoints, storesWithoutExcludeFromStock} from '../../libs';
import { userSetPurchased, userSetAvailableCoupons, userSetInfo } from '../../redux/actions';
import { excludeFromStockBtn, showAutumnCashback } from "../../settings";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

const btnStyle = {
  color: 'black',
  flex: '1 1 0',
  padding: 20,
  border: '1px solid green',
  margin: 6,
  fontSize: 18
};

const styles = () => ({
  root: defaultStyles.root,
  container: {...defaultStyles.container},
  disabled: {
    color: 'rgba(255, 255, 255, 0.2)',
  },
  buttonText: {
    color: 'rgba(255, 255, 255, 0.9)',
    fontWeight: '800',
    textAlign: 'center',
  },
  button: {
    height: '50%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  aboutUser: {
    color: 'rgba(255, 255, 255, 0.9)',
    textAlign: 'left',
    fontWeight: '700',
    paddingTop: '20px',
    marginLeft: '30px'
  },
  iconSize: {
    fontSize: '70px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '40px'
  }
});


class Component extends React.Component {
  state = {
    availableCoupons: [],
    isSMSModalOpen: false,
  };

  componentWillMount() {
    this.loadUserCoupons();
    this.loadAvailableCoupons();
    this.computeAvailableCoupons();
    //this.loadAutumn2020();

    getUserInfo(this.props.cardNumber, (response) => {
      this.props.userSetInfo(response);
    }, () => {});
  }

  componentWillReceiveProps(next) {
    const { cardNumber } = this.props;

    if (
      cardNumber && // userSessionKey &&
      next.cardNumber && // next.userSessionKey &&
      cardNumber !== next.cardNumber // || userSessionKey !== next.userSessionKey)
    ) {
      this.loadUserCoupons(next.cardNumber); //, next.userSessionKey);
      this.loadAvailableCoupons(next.cardNumber); //, next.userSessionKey);
      //this.loadAutumn2020(next.cardNumber); //, next.userSessionKey);
    }

    if (
      this.props.points !== next.points ||
      this.props.availableCoupons !== next.availableCoupons
    ) {
      this.computeAvailableCoupons(next.points, next.availableCoupons);
    }
  }

  loadUserCoupons(_cardNumber) {
    const { userId } = this.props;

    this.props.userSetPurchased([]);

    getUserCouponsAsync({
      userId,
      successCallback:  (response) => {
        this.props.userSetPurchased(response.filter(i => !i.isUsed).map(i => ({
          id: i.promoCodeValue,
          mindboxId: i.couponMindboxId,
          points: i.balanceCost,
          title: i.name,
          category: i.name,
          name: i.systemName,
          type: i.type,
          number: i.barcode,
          info: {
            points: i.balanceCost,
            title: i.name,
            type: i.type,
            category: i.name,
            name: i.systemName,
            barcode: i.barcode,
            number: i.barcode,
            id: i.barcode,
            min: i.sumRestriction,
            mindboxId: i.id,
          }
        })));
      },
      errorCallback: () => {}
    })
  }

  loadAvailableCoupons(_cardNumber) { // , _userSessionKey) {
    const cardNumber = _cardNumber || this.props.cardNumber;

    if (cardNumber) {
      this.props.userSetAvailableCoupons([]);

      getAvailableCoupons(cardNumber, (response) => {
        this.props.userSetAvailableCoupons(response.list);
      }, () => {
        alert('Пожалуйста, попробуйте еще раз', 'Доступные купоны не загружены');
      });
    }
  }

  computeAvailableCoupons(_points, _list) {
    const list = _list || this.props.availableCoupons;
    const points = _points || this.props.points;

    this.setState({
      availableCoupons: list.filter(item => item.points <= points),
    });
  }

  excludePurchase = () => {
    const { cardNumber, storeId, loads } = this.props;

    if (cardNumber && !loads) {
      excludePurchaseByCardName({cardNumber, storeId}, () => {
        this.props.history.push({pathname: 'success', state: {
          type: 'excludePurchase',
        }});
      }, () => {
        alert('Пожалуйста, попробуйте еще раз', 'Произошла ошибка');
      });
    }
  };

  loadAutumn2020 = () => {
    const { cardNumber } = this.props;

    loadAutumn2020(cardNumber, (balance) => {
      this.setState({
        autumn2020Balance: balance,
      });
    }, () => {
      this.setState({
        autumn2020Balance: null,
      });
    });
  };

  goto = (where) => {
    this.props.history.push(where);
  };

  render() {
    const purchasedCount = this.props.purchased.count();
    const isUserCoupons = purchasedCount > 0;
    const userListColor = isUserCoupons ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 0.2)';
    const availableCount = this.state.availableCoupons.count();
    const isAvailableCoupons = availableCount > 0;
    const availableListColor = isAvailableCoupons ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 0.2)';
    const { userInfo, classes, storeId } = this.props;

    const cashback = userInfo.cashback && parseInt(userInfo.cashback, 10);

    let isNotVlg;

    if (this.props.storeId < 23) {
      isNotVlg = true;
    }

    return (
      <>
        <Grid
          className={classes.root}
          container
          direction="row"
          alignItems="stretch"
        >
          <Header title="Операции" backButton="user">
            <Points />
          </Header>
          <Grid className={classes.container} container>
            <Grid
              xs={6}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '50%',
                backgroundColor: '#607D8B',
              }}
              item
            >
              {excludeFromStockBtn && storesWithoutExcludeFromStock.indexOf(storeId) === -1 && this.state.autumn2020Balance === null ? <Button
                style={{
                  alignItems: 'center',
                  background: 'rgba(255, 255, 255, 0.2)',
                  justifyContent: 'center',
                  padding: '0 20px',
                  margin: '25px auto 15px',
                  width: '370px',
                  height: 70,
                  cursor: 'pointer',
                  borderRadius: 0,
                  fontSize: 16,

                }}
                onClick={this.excludePurchase}
              >
                <Icon
                  style={{
                    fontSize: 42,
                    color: 'rgba(255, 255, 255, 0.9)',
                  }}
                >
                  highlight_off
                </Icon>
                <Grid
                  style={{
                    fontSize: '16px',
                    fontWeight: '800',
                    color: 'rgba(255, 255, 255, 0.9)',
                    marginLeft: '5px',
                  }}
                >
                  ИСКЛЮЧИТЬ ПОКУПКУ ИЗ АКЦИИ
                </Grid>
              </Button> : <Grid
                style={{
                  color: 'rgba(255, 255, 255, 0.9)',
                  fontWeight: '800',
                  textAlign: 'center',
                  marginTop: '30px',
                  marginBottom: '20px'
                }}
              >
                О КЛИЕНТЕ
              </Grid>}
              {/*isNotVlg && */showAutumnCashback && typeof this.state.autumn2020Balance === 'number' && <Grid className={classes.aboutUser} style={{
                border: '1px solid gold',
                padding: '13px 18px',
                alignSelf: 'flex-start'
              }}>
                Осенний кэшбэк: <span style={{ color: 'gold' }}>
              {formatPoints(this.state.autumn2020Balance)}
            </span>
              </Grid>}
              <Grid className={classes.aboutUser}>
                Баланс: <span style={{ color: 'gold' }}>{formatPoints(userInfo.totalBalance)}</span> бонусов
              </Grid>
              <Grid className={classes.aboutUser}>
                Кэшбэк: <span style={{ color: 'gold' }}>{cashback || 0}</span>%
              </Grid>
              {
                userInfo.alert && <Grid className={classes.aboutUser}>
                  Покупки с { moment(userInfo.alert.startDate).format('DD.MM.YY') }: <span style={{ color: 'gold' }}>
                    {formatPoints(userInfo.amassed || 0)}
                  </span> руб.
                </Grid>
              }
              {userInfo.nextCashback > 0 &&
              <>
                <Grid className={classes.aboutUser}>
                  До уровня <span style={{ color: 'gold' }}>{userInfo.nextCashback}</span>%: <span style={{ color: 'gold' }}>
                    {formatPoints(userInfo.remainsToBeAmassed || 0)}
                  </span> руб.
                </Grid>
              </>
              }
              {cashback === 10 && <div className={classes.aboutUser}>Максимальный уровень кэшбэка</div>}
            </Grid>

            {
              userInfo.alert ? (
                <Grid
                  item
                  xs={6}
                  style={{
                    backgroundColor: '#FF4081',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '50%',
                  }}
                >
                  <Grid
                    style={{
                      color: 'rgba(255, 255, 255, 0.9)',
                      fontWeight: '800',
                      textAlign: 'center',
                      marginTop: '30px',
                      marginBottom: '20px'
                    }}
                  >
                    ИЗМЕНЕНИЕ БОНУСНОГО УРОВНЯ
                  </Grid>
                  <Grid className={classes.aboutUser}>
                    Кэшбэк после { moment(userInfo.alert.alertDate).format('DD.MM.YY') }: <span style={{ color: 'gold' }}>{userInfo.lowerLevel}</span>%
                  </Grid>
                  <Grid className={classes.aboutUser}>
                    Для сохранения <span style={{ color: 'gold' }}>{userInfo.alert.level}</span>%: <span style={{ color: 'gold' }}>{
                      formatPoints(userInfo.alert.amountToSaveLevel)
                    }</span> руб.
                  </Grid>
                </Grid>
              )
                :
                <Grid
                  item
                  xs={6}
                  style={{
                    backgroundColor: '#607D8B',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '50%',
                  }}
                >
                </Grid>
            }


            <Grid
              item
              xs={6}
              className={classes.button}
              onClick={() => {
                if (isUserCoupons) {
                  this.goto('user-coupons');
                }
              }}
            >
              <Grid
                style={{
                  background: '#2196F3',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid
                  className={classes.buttonText}
                  style={{
                    paddingTop: '85px',
                    marginBottom: 0,
                    color: userListColor,
                  }}
                >
                  КУПЛЕННЫЕ КУПОНЫ
                </Grid>
                <Grid
                  className={classes.buttonText}
                  style={{
                    marginLeft: 10,
                    color: isUserCoupons ? 'gold' : userListColor,
                  }}
                >
                  {purchasedCount > 100 ? '100+' : purchasedCount}
                </Grid>
                <Icon
                  className={classes.iconSize}
                  style={{ color: userListColor }}
                >
                  playlist_add_check
                </Icon>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ backgroundColor: '#008080' }}
              className={classes.button}
              onClick={() => {
                if (!this.props.isPhoneConfirmed && isAvailableCoupons) {
                  this.setState({
                    isSMSModalOpen: true
                  });
                  return;
                }

                if (isAvailableCoupons) {
                  this.goto('available-coupons');
                }
              }}
            >
              <Grid
                style={{
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid
                  className={classes.buttonText}
                  style={{
                    marginBottom: 0,
                    color: availableListColor,
                    paddingTop: '85px'
                  }}
                >
                  СПИСАТЬ БОНУСЫ
                </Grid>
                <Grid
                  className={classes.buttonText}
                  style={{
                    marginLeft: 10,
                    color: isAvailableCoupons ? 'gold' : availableListColor,
                  }}
                >
                  { this.props.pointsFormatted }
                </Grid>
                <Icon
                  className={classes.iconSize}
                  style={{
                    color: availableListColor
                  }}
                >
                  add_shopping_cart
                </Icon>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Dialog onClose={() => {
          this.setState({
            isSMSModalOpen: false
          })
        }} open={this.state.isSMSModalOpen}>
          <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center'}}>Выберите способ предъявления карты</DialogTitle>
          {/*<DialogContent>*/}
            {/*<DialogContentText id="alert-dialog-description">*/}
            {/*  */}
            {/*</DialogContentText>*/}
          {/*</DialogContent>*/}
          <DialogActions>
            <div style={{ display: 'flex', flex: 'row nowrap', width: '100%' }}>
              <Button onClick={() => {
                if (isAvailableCoupons) {
                  this.goto('available-coupons');
                }
              }} color="primary" style={btnStyle}>
                Пластиковая карта\<br/>
                мобильное приложение
              </Button>
              <Button onClick={() => {
                const phone = this.props.phone.replace('+', '');
                sendSMSForPhoneEntrance({
                  phone,
                }, () => {
                  this.props.history.push({ pathname: 'enter-phone-confirm', state: { phone, type: 'phone-entrance', target: 'available-coupons' }});
                }, (errorMessage = 'Пожалуйста, попробуйте еще раз') => {
                  alert(errorMessage);
                });
              }} color="primary" style={btnStyle}>
                <div>
                  <span>Электронный кошелек</span>
                  <div style={{ fontSize: 12, maxWidth: 210, textAlign: 'center', textTransform: 'none', lineHeight: '1.5em', color: '#999999', marginTop: 8}}>
                    Будет отправлено смс с кодом подтверждения на номер<br/>{getPhoneMasked(this.props.phone)}.
                  </div>
                </div>
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

Component.propTypes = {
  cardNumber: PropTypes.string.isRequired,
  points: PropTypes.string.isRequired,
  pointsFormatted: PropTypes.string.isRequired,
  purchased: PropTypes.objectOf(List),
  availableCoupons: PropTypes.objectOf(List),
  userInfo: PropTypes.object,
  userSetPurchased: PropTypes.func,
  userSetAvailableCoupons: PropTypes.func,
  userSetInfo: PropTypes.func,
  storeId: PropTypes.number,
  loads: PropTypes.number,
  phone: PropTypes.string,
  isPhoneConfirmed: PropTypes.bool,
};

export default connect(state => ({
  cardNumber: state.user.get('cardNumber'),
  points: state.user.get('points'),
  pointsFormatted: state.user.get('pointsFormatted'),
  purchased: state.user.get('purchased'),
  availableCoupons: state.user.get('availableCoupons'),
  userInfo: state.user.get('userInfo'),
  storeId: state.operator.get('storeId'),
  loads: state.app.get('loads'),
  phone: state.user.get('phone'),
  isPhoneConfirmed: state.user.get('isPhoneConfirmed'),
  userId: state.user.get('userId'),
}), {
  userSetPurchased,
  userSetAvailableCoupons,
  userSetInfo,
})(withStyles(styles)(Component));

function getPhoneMasked(phone) {
  if (!phone) return '';
  return `${phone.slice(0,2)}-${phone.slice(2, 8).replace(/./g, '*')}-${phone.slice(8, 10)}-${phone.slice(10, 12)}`;
}
