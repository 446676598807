import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import {defaultStyles} from "../../settings";
import NewClientButton from '../common/NewClientButton';
import SecondaryButton from '../common/SecondaryButton';
import {trackEvent} from "../../../src/libs";

const styles = () => ({
  root: defaultStyles.root,
});

class Component extends React.Component {
  state = {
    counter: 15,
  };

  componentDidMount() {
    this.interval = setInterval(this.countDown, 1000);
    trackEvent('Success');
  }

  componentWillUnmount() {
    this.interval = clearInterval(this.interval);
  }

  done = () => {
    const { type } = this.props.location.state;

    const toDashBoard = type === 'returnedFromUserCoupons' ||
      type === 'returnedFromAvailableCoupons' ||
      type === 'cardActivatedByPhone';

    this.goto(toDashBoard ? 'dashboard' : 'user')
  };

  countDown = () => {
    const left = this.state.counter;

    if (left === 0) {
      this.done();
    } else {
      this.setState({
        counter: left - 1,
      });
    }
  };

  goto = (where) => {
    this.props.history.push(where);
  };

  render() {
    const { classes } = this.props;
    const { type, source } = this.props.location.state;

    console.log("source", source);

    const toDashBoard = type === 'returnedFromUserCoupons' ||
      type === 'returnedFromAvailableCoupons' ||
      type === 'cardActivatedByPhone';

    let text = 'Купон погашен';

    if (type === 'returnedFromUserCoupons') {
      text = 'Возврат осуществлен';
    }

    if (type === 'returnedFromAvailableCoupons') {
      text = 'Списание отменено';
    }

    if (type === 'excludePurchase') {
      text = 'Покупка не участвует в акции';
    }

    if (type === 'cardActivatedByPhone') {
      text = 'Карта активирована';
    }

    return (
      <Grid
        className={classes.root}
        container
        direction="row"
        alignItems="stretch"
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="flex-end"
        >
          <Grid
            style={{
              textAlign: 'center',
              marginBottom: '160px'
            }}
          >
            <Icon
              name="check-circle"
              style={{
                color: 'green',
                fontSize: '75px',
              }}
            >
              check_circle
            </Icon>
            <Grid
              style={{
                fontWeight: '600',
                color: '#333333',
                fontSize: '45px',
                textAlign: 'center',
                paddingTop: '20px'
              }}
            >
              {text}
            </Grid>
          </Grid>

          <NewClientButton
            onPress={() => this.goto(toDashBoard ? 'dashboard' : 'user')}
            counter={this.state.counter}
            toDashBoard={toDashBoard}
            styles={type === 'excludePurchase' && {
              marginBottom: 30
            }}
          />
          {
            type === 'excludePurchase' &&
            <SecondaryButton
              onPress={() => this.goto(source || 'dashboard')}
              text={'ВЕРНУТЬСЯ К КЛИЕНТУ'}
              toDashBoard={true}
            />
          }
        </Grid>
      </Grid>
    );
  }
}

Component.propTypes = {
  loads: PropTypes.any,
};

export default connect(state => ({
  loads: state.app.get('loads'),
}), {})(withStyles(styles)(Component));
