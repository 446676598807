import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Keyboard from '../common/keyboard/Keyboard';
import Dot from '../common/Dot';
import { operatorSetRegister } from '../../redux/actions';
import {defaultStyles} from "../../settings";
import {trackEvent, setOperatorRegister} from "../../libs";


const styles = theme => ({
  root: defaultStyles.root,
  dot: {
    // padding: theme.spacing.unit * 2,
    color: theme.palette.text.disabled,
    fontSize: 60,
    width: 53,
    padding: 0,
    textAlign: 'center',
  },
  activeDot: {
    color: theme.palette.text.primary,
    fontSize: 60,
    fontWeight: 'bold',
    width: 53,
    padding: 0,
    textAlign: 'center',
  },
});

class Component extends React.Component {
  state = { register: '' };

  onPress = (keyboardValue) => {
    this.setState({
      register: keyboardValue,
    });
  };

  onPressEnter = () => {
    this.authorize();
  };

  authorize = () => {
    trackEvent('Set Number Of Cashbox', this.state.register);
    this.props.operatorSetRegister(this.state.register);
    setOperatorRegister(this.state.register);
  };

  render() {
    const { classes } = this.props;
    const { register } = this.state;

    return (
      <Grid
        className={classes.root}
        container
        direction="row"
        alignItems="stretch"
      >
        {/* <StatusBar hidden /> */}
        {/* <ProgressBar addScore={this.state.password.length * (0.57 / 4)} /> */}
        <Grid
          item
          xs
          container
          direction="column"
          alignItems="stretch"
          justify="center"
        >
          <Grid
            item
            style={{
              textAlign: 'center',
              fontSize: 40,
            }}
          >
            Введите номер кассы
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="center"
            wrap="nowrap"
            style={{
              marginTop: 50,
              marginBottom: 50,
            }}
          >
            <Dot classes={classes} value={register[0]} />
            <Dot classes={classes} value={register[1]} />
          </Grid>
        </Grid>
        <Keyboard
          value={register}
          onPress={this.onPress}
          onEnter={this.onPressEnter}
          minLength={1}
          maxLength={2}
        />
      </Grid>
    );
  }
}

Component.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.any.isRequired,
    activeDot: PropTypes.any.isRequired,
    dot: PropTypes.any.isRequired,
  }).isRequired,

  operatorSetRegister: PropTypes.func.isRequired,
};

export default connect(null, { operatorSetRegister })(withStyles(styles)(Component));
