import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Keyboard from '../common/keyboard/Keyboard';
import Dot from '../common/Dot';
import { defaultStyles } from "../../settings";
import Header from './Header/Header';
import Points from './Header/Points';
import { sendSMSForCardActivation } from "../../api";

const styles = theme => ({
  root: defaultStyles.root,
  container: {...defaultStyles.container},
  dot: {
    color: theme.palette.text.disabled,
    fontSize: 24,
    margin: '0 1px',
    width: 13,
  },
  activeDot: {
    color: theme.palette.text.primary,
    fontSize: 24,
    fontWeight: 'bold',
    width: 15,
  },
});

const PHONE_COUNTRY = '7';

class Component extends React.Component {
  constructor(props) {
    super(props);

    this.onPress = this.onPress.bind(this);
    this.onPressEnter = this.onPressEnter.bind(this);
    this.handleValue = this.handleValue.bind(this);

    this.state = {
      value: '',
    };
  }

  onPress(keyboardValue) {
    this.setState({
      value: keyboardValue,
    });
  }

  onPressEnter() {
    this.handleValue();
  }

  handleValue() {
    const phone = `${PHONE_COUNTRY}${this.state.value}`;

    const { type } = this.props.location.state;

    if (type === 'card-activation') {
      sendSMSForCardActivation({
        phone,
        cardNumber: this.props.cardNumber,
      }, () => {
        this.props.history.push({ pathname: 'enter-phone-confirm', state: { phone, type }});
      }, (errorMessage = 'Пожалуйста, попробуйте еще раз') => {
        alert(errorMessage);
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const { type } = this.props.location.state;

    let text;

    if (type === 'card-activation') {
      text = 'для активации карты';
    }

    return (
      <Grid
        className={classes.root}
        container
        direction="row"
        alignItems="stretch"
      >
        <Header title="Актвация карты" backButton="dashboard">
          <Points />
        </Header>
        <Grid
          className={classes.container}
          container
          direction="row"
          alignItems="stretch"
        >
          <Grid
            item
            xs
            container
            direction="column"
            alignItems="stretch"
            justify="center"
          >
            <Grid
              item
              style={{
                textAlign: 'center',
                fontSize: 30,
                matginTop: -30,
              }}
            >
              Введите номер <strong>телефона</strong>
              <br />
              {text}
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              style={{ paddingTop: 40 }}
            >
              <Grid
                style={{
                  fontWeight: '500',
                  fontSize: 24,
                  marginRight: 15,
                }}
              >
                +7
              </Grid>

              <Dot
                classes={classes}
                value={value[0]}
              />
              <Dot
                classes={classes}
                value={value[1]}
              />
              <Dot
                classes={classes}
                value={value[2]}
                style={{ marginRight: 14 }}
              />
              <Dot
                classes={classes}
                value={value[3]}
              />
              <Dot
                classes={classes}
                value={value[4]}
              />
              <Dot
                classes={classes}
                value={value[5]}
                style={{ marginRight: 14 }}
              />
              <Dot
                classes={classes}
                value={value[6]}
              />
              <Dot
                classes={classes}
                value={value[7]}
                style={{ marginRight: 14 }}
              />
              <Dot
                classes={classes}
                value={value[8]}
              />
              <Dot
                classes={classes}
                value={value[9]}
              />
            </Grid>
          </Grid>
          <Keyboard
            value={value}
            onPress={this.onPress}
            onEnter={this.onPressEnter}
            minLength={10}
            maxLength={10}
          />
        </Grid>
      </Grid>
    );
  }
}

Component.propTypes = {
  dispatch: PropTypes.func.isRequired,
  cardNumber: PropTypes.string.isRequired,
};

export default connect(state => ({
  cardNumber: state.user.get('cardNumber'),
}))(withStyles(styles)(Component));
