/* global fetch, __DEV__ */
// global fetch, __DEV__, window

import { DateTime } from 'luxon';
// import { Buffer } from 'buffer';
import { api } from './settings';
import { appAddLoad, appRemoveLoad } from './redux/actions';
import store from './redux/store';
import { pad } from './libs';

const host = window.location.hostname.match(/(\.review\.|localhost)/) ? api.test : api.prod;
// let host = api.test;

// @todo Использовать тестовый API
// if (true || __DEV__ === false) {
//   host = api.prod;OBI-491
// }

export const getCategory = title => title.replace(/Офлайн купон/, 'Купон').replace(/Онлайн купон/, 'Купон');
const getCoupon = (item) => {
  // const additional = JSON.parse(node.customFields.prizeAdditinalInformation);
  // const barcode = additional.barcode.replace(/[^0-9]/, '');

  if (!item.balanceCost) {
    return null;
  }

  return {
    points: item.balanceCost,
    title: item.name,
    type: item.type,
    category: getCategory(item.name),
    name: item.systemName,
    barcode: item.info.barcode,
    number: item.info.barcode,
    id: item.info.barcode,
    min: item.info.sumRestriction,
    mindboxId: item.id,
  };
};

const increaseLoads = () => {
  store.dispatch(appAddLoad());
};

const decreaseLoads = () => {
  store.dispatch(appRemoveLoad());
};

const getHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  obiStoreId: pad(store.getState().operator.get('storeId'), 3),
  obiCashDeskId: pad(store.getState().operator.get('register'), 2),
  // Authorization: `Mindbox secretKey="${params.secretKey || 'ZNkGodQ2fNy8UgAf5bhz'}"`,
});

const getRequest = (uri, _params = {}, success, error) => {
  const params = {
    method: 'GET',
    headers: getHeaders(_params),
  };

//   request(host.url + uri, params, success, error, );

  increaseLoads();

  fetch(uri, params).then(response => response.json()).then((responseJson) => {
    decreaseLoads();

    if (success) {
      success(responseJson);
    }
  }).catch((response) => {
    decreaseLoads();

    if (error) {
      error(response);
    }
  });
};

const postRequest = (uri, data, success, error, dontDecreaseLoad) => {
  const params = {
    method: 'POST',
    headers: getHeaders(),
  };

  if (data) {
    params.body = JSON.stringify(data); // Buffer.from(data); // Buffer - для обхода бага
  }

  increaseLoads();

  const url = uri.includes('http') ? uri : host.url + uri;

  fetch(url, params).then((response) => {
    if (!dontDecreaseLoad) {
      decreaseLoads();
    }

    response.json().then((res) => {
      if (success) {
        success(res);
      }
    }).catch((err) => {
      if (error) {
        error(err);
      }
    });
  }).catch((response) => {
    if (!dontDecreaseLoad) {
      decreaseLoads();
    }

    if (error) {
      error(response);
    }
  });
};

// const postRequest = (uri, data, success, error, , dontDecreaseLoad) => {
//   const params = {
//     method: 'POST',
//     headers: getHeaders(),
//   };
//
//   if (data) {
//     params.body = JSON.stringify(data); // Buffer.from(data); // Buffer - для обхода бага
//   }
//
//   increaseLoads();
//
//   const url = uri.includes('http') ? uri : host.url + uri;
//
//   axios({ url, data, method: 'POST' }).then((response) => {
//     axios.defaults.headers.post['Content-Type'] = 'application/json';
//
//     if (!dontDecreaseLoad) {
//       decreaseLoads();
//     }
//
//     response.json().then((res) => {
//       if (success) {
//         success(res);
//       }
//     }).catch((err) => {
//       if (error) {
//         error(err);
//       }
//     });
//   }).catch((response) => {
//     if (!dontDecreaseLoad) {
//       decreaseLoads();
//     }
//
//     if (error) {
//       error(response);
//     }
//   });
// };

const getUserPoints = (response) => {
  const points = parseInt(response.balances[0].available, 10);

  if (Number.isNaN(points)) {
    return 0;
  }

  return points;
};

const getUserCard = (response, cardNumber) => {
  if (!response.discountCards || !response.discountCards[0]) {
    return false;
  }

  const card = response.discountCards.find(i => (
    (i.type === 'CAP2022' || i.type === 'CAPVip' || i.type === 'VirtualCard2022' || i.type === 'CAPNewSettler2022' || i.type === 'Профи-2022' || i.type === 'Профи8-2022' || i.type === 'Профи-2023' || i.type === 'Виртуальная карта-2023' || i.type === 'CAP-2023' || i.type === 'CAP NewSettler-2023') && i.cardNumber === cardNumber
  ));


  if (!card) {
    return false;
  }

  return {
    number: card.cardNumber,
    status: card.status,
    type: card.type,
  };
};

const getCardStatus = (cardNumber, successCallback, errorCallback) => {
  const url = `${host}/graphql/cash-desk?query=query ($credentials: CustomerCredentialsInputType) {
    discountCard(credentials: $credentials) { status }
  }
  &variables={
    "credentials": {
      "cardNumber": "${cardNumber}"
    }
  }`;

  postRequest(url, null, (response) => {
    const status = response.data && response.data.discountCard && response.data.discountCard.status;
    if (status) {
      successCallback(status);
    } else {
      errorCallback(response);
    }
  }, (error) => {
    errorCallback(error);
  });
};

export const getUser = ({ discountCard, successCallback, errorCallback }) => {
  postRequest(`${host}/api/mindbox/customer/get`, { discountCard }, (response) => {
    successCallback(response);
  }, (error) => {
    errorCallback(error);
  });
};

export const getUserByCardNumber = (
  discountCard,
  successCallback,
  errorCallback,
) => {
  postRequest(
    `${host}/api/mindbox/customer/find`,
    { discountCard },
    (response) => {
      if (!successCallback) {
        return;
      }

      getCardStatus(
        discountCard,
        (status) => {
          if (status === 'Blocked') {
            errorCallback(
              response,
              'Карта заблокирована',
              'Используйте другую карту для входа',
            );

            return;
          }

          if (status === 'OnActivation') {
            errorCallback(
              response,
              'Карта на активации',
              'Используйте другую карту для входа',
            );

            return;
          }

          if (status === 'Utilized') {
            errorCallback(
              response,
              'Карта утилизирована',
              'Используйте другую карту для входа',
            );

            return;
          }

          if (status === 'Inactive' || status === 'NotIssued' || status === 'Issued') {
            errorCallback(
              response,
              'Карта не активирована',
              'Необходимо активировать карту на сайте или по телефону',
              true,
            );

            return;
          }

          const card = getUserCard(response, discountCard);

          if (!card) {
            errorCallback(response);
            return;
          }

          if (card.status === 'Activated') {
            successCallback({
              cardNumber: card.number,
              points: response.balances[0].available,
              mindboxId: response.mindboxId,
              card,
              phone: response.phone,
              userId: response.userId,
              userSecretKey: response.userSecretKey,
            });
          } else {
            errorCallback(response);
          }
        },
        (err) => {
          errorCallback(response);
        },
      );
    },
    (error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    },
  );
};

export const getUserByPhone = (phone, successCallback, errorCallback) => {
  postRequest(
    `${host}/api/mindbox/customer/find`,
    { phone },
    (response) => {
      if (!successCallback) {
        return;
      }

      if (!response.discountCards || !response.discountCards[0]) {
        errorCallback(response);
        return;
      }

      let card = response.discountCards.find(i => i.type === 'CAPVip' && i.status === 'Activated');

      if (!card) {
        card = response.discountCards.find(i => i.type === 'CAP2022' && i.status === 'Activated');
      }

      if (!card) {
        card = response.discountCards.find(i => i.type === 'Профи-2022' && i.status === 'Activated');
      }

      if (!card) {
        card = response.discountCards.find(i => i.type === 'Профи8-2022' && i.status === 'Activated');
      }

      if (!card) {
        card = response.discountCards.find(i => i.type === 'VirtualCard2022' && i.status === 'Activated');
      }

      if (!card) {
        card = response.discountCards.find(i => i.type === 'CAPNewSettler2022' && i.status === 'Activated');
      }

      if (!card) {
        card = response.discountCards.find(i => i.type === 'Профи-2023' && i.status === 'Activated');
      }

      if (!card) {
        card = response.discountCards.find(i => i.type === 'Виртуальная карта-2023' && i.status === 'Activated');
      }

      if (!card) {
        card = response.discountCards.find(i => i.type === 'CAP-2023' && i.status === 'Activated');
      }

      if (!card) {
        card = response.discountCards.find(i => i.type === 'CAP NewSettler-2023' && i.status === 'Activated');
      }

      if (card) {
        successCallback({
          cardNumber: card.cardNumber,
          points: getUserPoints(response),
          userId: response.userId,
          userSecretKey: response.userSecretKey,
        });
      } else {
        errorCallback(response);
      }
    },
    (error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    },
  );
};

export const getAvailableCoupons = (discountCard, successCallback, errorCallback) => {
  postRequest(
    `${host}/api/mindbox/prize/getAvailableList/`,
    { discountCard },
    (response) => {
      if (!successCallback) {
        return;
      }

      const list = response.prizes
        .map(item => getCoupon(item))
        .filter(i => i && i.type === 'offline')
        .sort((a, b) => a.points - b.points);

      successCallback({ list });
    }, (error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    },
  );
};

export const getUserCoupons = (discountCard, successCallback, errorCallback) => {
  postRequest(
    `${host}/api/mindbox/prize/getList/`,
    { discountCard },
    (response) => {
      if (!successCallback) {
        return;
      }

      const list = response.customerActions
        .filter(i => !i.customerPrize[0].promoCode[0].isUsed)
        .map(item => ({
          id: item.customerPrize[0].promoCode[0].value,
          mindboxId: item.customerPrize[0].mindboxId,
          points: Math.abs(parseInt(item.customerPrize[0].prize[0].info.sumRestriction, 10) / 2),
          title: item.customerPrize[0].prize[0].name,
          category: getCategory(item.customerPrize[0].prize[0].name),
          name: item.customerPrize[0].prize[0].systemName,
          type: item.customerPrize[0].prize[0].type,
          number: item.customerPrize[0].prize[0].info.barcode,
        }))
        .sort((_a, _b) => {
          const a = _a.points;
          const b = _b.points;

          if (a < b) {
            return -1;
          }

          if (a > b) {
            return 1;
          }

          return 0;
        });

      successCallback({
        list,
      });
    }, (error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    },
  );
};

export const getUserCouponsAsync = ({
  userId, successCallback, errorCallback,
}) => {
  const request = (successIterationCallback) => {
    postRequest(
      `${host}/api/mindbox/prize/getPlainList/`,
      { userId },
      (response) => {
        if (!successCallback) {
          return;
        }
        successIterationCallback(JSON.parse(response));
      }, (error) => {
        if (errorCallback) {
          errorCallback(error);
        }
      },
    );
  };

  let timesLeft = 20;
  const iterationTimeout = 700;

  const successIterationCallback = (response) => {
    const coupon = response[0];
    if (!coupon) {
      errorCallback(response);
    }

    if (coupon.processingStatus === false) {
      timesLeft -= 1;
      if (timesLeft > 0) {
        setTimeout(() => {
          request(successIterationCallback);
        }, iterationTimeout);
      } else {
        errorCallback(response);
      }
    } else {
      successCallback(response);
    }
  };

  request(successIterationCallback);
};

export const getUserBalanceAsync = ({
  userId, successCallback, errorCallback,
}) => {
  const request = (successIterationCallback) => {
    postRequest(
      `${host}/api/mindbox/customer/getBalanceByUserId/`,
      { userId },
      (response) => {
        if (!successCallback) {
          return;
        }
        successIterationCallback(response[0]);
      }, (error) => {
        if (errorCallback) {
          errorCallback(error);
        }
      },
    );
  };

  let timesLeft = 20;
  const iterationTimeout = 700;

  const successIterationCallback = (response) => {
    if (response.processingStatus === false) {
      timesLeft -= 1;
      if (timesLeft > 0) {
        setTimeout(() => {
          request(successIterationCallback);
        }, iterationTimeout);
      } else {
        errorCallback(response);
      }
    } else {
      successCallback(response);
    }
  };

  request(successIterationCallback);
};

export const loadCouponByName = (systemName, successCallback, errorCallback) => {
  postRequest(
    `${host}/api/mindbox/prize/getInfo/`,
    { systemName },
    (response) => {
      if (!successCallback) {
        return;
      }

      successCallback({
        coupon: getCoupon(response),
      });
    },
    (error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    },
  );
};

// export const loadCouponByNumber = (
//   systemName,
//   successCallback,
//   errorCallback,
// ) => {
//   postRequest(
//     `${host}/api/mindbox/prize/getInfo/`,
//     { systemName },
//     (response) => {console.log('response', response);
//       if (!successCallback) {
//         return;
//       }
//
//       const getPoints = (title) => {
//         const matches = /(\d+)\sрублей/i.exec(title);
//         return matches && matches[1] ? parseInt(matches[1], 10) : false;
//       };
//
//       const d = response.discounts.promoCode;
//
//       successCallback({
//         coupon: {
//           number: d.id,
//           from: new Date(d.availableFromDateTimeUtc),
//           till: new Date(d.availableTillDateTimeUtc),
//           title: d.description,
//           category: getCategory(d.description),
//           points: getPoints(d.description),
//           isAvailable: d.isAvailable === 'true' && d.status === 'CanBeUsed',
//         },
//       });
//     },
//     (error) => {
//       if (errorCallback) {
//         errorCallback(error);
//       }
//     },
//   );
// };

/**
 * Покупка купона
 */
export const buyCoupon = ({
  userId,
  userSecretKey,
  discountCard,
  systemName,
  balanceCallback,
  boughtCouponCallback,
  errorCallback,
}) => {
  postRequest(
    `${host}/api/mindbox/prize/plainBuy/`,
    { userId, userSecretKey, systemName },
    (response) => {
      if (!boughtCouponCallback || !response.success) {
        decreaseLoads();
        errorCallback(response);
        return;
      }

      // Баланс

      // getUserInfo(discountCard, (userResponse) => {
      //   decreaseLoads();
      //   console.log('userResponse', userResponse);
      //   balanceCallback(userResponse);
      // }, (userError) => {
      //   decreaseLoads();
      //   console.log('xxxxxxxx', userError);
      //   errorCallback(userError);
      // });

      getUserBalanceAsync({
        userId,
        successCallback: (balanceResponse) => {
          balanceCallback(balanceResponse);
        },
        errorCallback: (balanceError) => {
          errorCallback(balanceError);
        },
      });

      getUserCouponsAsync({
        userId,
        successCallback: (res) => {
          const coupons = res.filter(i => !i.isUsed);

          if (coupons.length === 0) {
            console.log('no coupons');
            return;
          }

          const coupon = coupons[0];

          // const couponInfo = res.prizes.find(i => i.id === prize.customerPrize[0].mindboxId);

          decreaseLoads();

          boughtCouponCallback({
            id: coupon.promoCodeValue,
            mindboxId: coupon.couponMindboxId,
            name: coupon.systemName,
            info: getCoupon({
              ...coupon,
              info: {
                barcode: coupon.barcode,
                sumRestriction: coupon.sumRestriction,
              },
              id: coupon.couponMindboxId,
            }),
          });
        },
        errorCallback: (error) => {
          errorCallback(error);
        },
      });
    },
    (error) => {
      if (errorCallback) {
        decreaseLoads();
        errorCallback(error);
      }
    },
    true,
  );
};

/**
 * Гашение купона
 */
export const payCouponOld = (
  promoCodeValue,
  discountCard,
  mindboxId,
  successCallback,
  errorCallback,
) => {
  postRequest(
    `${host}/api/mindbox/prize/use/`,
    {
      discountCard,
      promoCodeValue,
      usedDateTimeUtc: DateTime.utc().toFormat('yyyy-MM-dd HH:mm:ss'),
      prizeMindboxId: mindboxId,
    },
    (response) => {
      if (!successCallback || response.status !== 'Success') {
        return;
      }

      successCallback(response);
    },
    (error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    },
  );
};

export const payCoupon = ({
  promoCodeValue,
  mindboxId,
  userId,
  userSecretKey,
  successCallback,
  errorCallback,
}) => {
  postRequest(
    `${host}/api/mindbox/prize/plainUse/`,
    {
      userId,
      userSecretKey,
      promoCodeValue,
      usedDateTimeUtc: DateTime.utc().toFormat('yyyy-MM-dd HH:mm:ss'),
      prizeMindboxId: mindboxId,
    },
    (response) => {
      if (!successCallback || !response.success) {
        return;
      }

      successCallback(response);
    },
    (error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    },
  );
};

export const returnCoupon = ({
  mindboxId, userId, userSecretKey, successCallback, errorCallback, balanceCallback,
}) => {
  postRequest(
    `${host}/api/mindbox/prize/plainReturn/`,
    { prizeMindboxId: mindboxId, userId, userSecretKey },
    (response) => {
      if (!successCallback || !response.success) {
        return;
      }

      getUserBalanceAsync({
        userId,
        successCallback: (balanceResponse) => {
          balanceCallback(balanceResponse);
        },
        errorCallback: (balanceError) => {
          errorCallback(balanceError);
        },
      });

      successCallback(response);
    },
    (error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    },
  );
};

export const getUserInfo = (discountCard, successCallback, errorCallback) => {
  postRequest(
    `${host}/api/mindbox/customer/getLevelInfo/`,
    { discountCard },
    (response) => {
      if (!successCallback) {
        return;
      }

      successCallback(response);
    },
    (error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    },
  );
};

export const ping = (params, successCallback) => {
  let storeId = params.storeId.toString();

  if (storeId.length === 2) {
    storeId = `0${storeId}`;
  }

  if (storeId.length === 1) {
    storeId = `00${storeId}`;
  }

  postRequest(
    `${host}/graphql?query=mutation {cash_desk_ping(store_obi_id:"${storeId}", cash_desk_id:"${params.cashboxId}", app_version:"1.0.55"){status}}`,
    null,
    (response) => {
      const status = response.data && response.data.cash_desk_ping && response.data.cash_desk_ping.status;
      successCallback(!!status);
    },
    () => {
      successCallback(false);
    },
  );
};

export const excludePurchaseByCardName = (params, successCallback, errorCallback) => {
  const url = `${host}/graphql/cash-desk?query=mutation ($credentials: CustomerCredentialsInputType!) {
      promotions {
        autumn2020 {
          excludePurchase(credentials: $credentials)
        }
      }
    }
    &variables={
      "credentials": {
        "cardNumber": "${params.cardNumber}"
      }
    }`;

  postRequest(url, null, (response) => {
    if (!successCallback) {
      return;
    }

    const status = response.data && response.data.promotions
      && response.data.promotions.autumn2020 && response.data.promotions.autumn2020.excludePurchase;

    if (status) {
      successCallback();
    } else {
      errorCallback();
    }
  }, (error) => {
    if (errorCallback) {
      errorCallback(error);
    }
  });
};

export const loadAutumn2020 = (cardNumber, successCallback, errorCallback) => {
  const url = `${host}/graphql/cash-desk?query=query($credentials: CustomerCredentialsInputType!) {
    promotions {
      autumn2020 {
        isInvolvedInAutumn2020(credentials: $credentials)
        customer(credentials: $credentials) {
          balance(systemName: "KeshbekOsen2020") {
            total
          }
        }
      }
    }
  }
  &variables={
    "credentials": {
      "cardNumber": "${cardNumber}"
    }
  }`;

  postRequest(url, null, (response) => {
    const isInvolved = response.data && response.data.promotions
      && response.data.promotions.autumn2020 && response.data.promotions.autumn2020.isInvolvedInAutumn2020;

    const balance = response.data && response.data.promotions
      && response.data.promotions.autumn2020 && response.data.promotions.autumn2020.customer
      && response.data.promotions.autumn2020.customer.balance
      && response.data.promotions.autumn2020.customer.balance.total;

    if (isInvolved === true && typeof balance === 'number') {
      successCallback(balance);
    } else {
      errorCallback(response);
    }
  }, (error) => {
    errorCallback(error);
  });
};

export const sendSMSForCardActivation = (params, successCallback, errorCallback) => {
  const url = `${host}/graphql/cash-desk?query=query ($credentials: CustomerCredentialsInputType) {
    discountCard(credentials: $credentials) {
      cardId
      cardNumber
      clientCode
      status
      type
      sendSMSCodeAuthorization(credentials: $credentials)
    }
  }
  &variables={
    "credentials": {
      "phone": "${params.phone}",
      "cardNumber": "${params.cardNumber}"
    }
  }`;

  postRequest(url, null, (response) => {
    if (!successCallback || !errorCallback) {
      return;
    }

    if (response.errors && response.errors.length) {
      errorCallback(response.errors[0].message);
    } else if (response.data && response.data.discountCard) {
      successCallback(response.data.discountCard);
    } else {
      errorCallback();
    }
  }, (error) => {
    if (errorCallback) {
      errorCallback(error);
    }
  });
};

export const confirmSMSForCardActivation = (params, successCallback, errorCallback) => {
  const url = `${host}/graphql/cash-desk?query=query ($credentials: CustomerCredentialsInputType) {
      discountCard(credentials: $credentials) {
      cardId
      cardNumber
      clientCode
      status
      type
      activateByPhone(credentials: $credentials)
    }
  }
  &variables={
    "credentials": {
      "phone": "${params.phone}",
      "cardNumber": "${params.cardNumber}",
      "authenticationCode": "${params.authenticationCode}"
    }
  }`;

  postRequest(url, null, (response) => {
    if (!successCallback) {
      return;
    }

    if (response.errors && response.errors.length) {
      errorCallback(response.errors[0].message);
    } else if (response.data && response.data.discountCard) {
      successCallback();
    } else {
      errorCallback();
    }
  }, (error) => {
    if (errorCallback) {
      errorCallback(error);
    }
  });
};

export const sendSMSForPhoneEntrance = (params, successCallback, errorCallback) => {
  const url = `${host}/graphql/cash-desk?query=mutation($credentials: CustomerCredentialsInputType) {
    sendSMSCodeAuthorization(credentials: $credentials)
  }
  &variables={
    "credentials": {
      "phone": "${params.phone}"
    }
  }`;

  postRequest(url, null, (response) => {
    if (!successCallback || !errorCallback) {
      return;
    }

    if (response.errors && response.errors.length) {
      errorCallback(response.errors[0].message);
    } else if (response.data && response.data.sendSMSCodeAuthorization) {
      successCallback();
    } else {
      errorCallback();
    }
  }, (error) => {
    if (errorCallback) {
      errorCallback(error);
    }
  });
};

export const confirmSMSForPhoneEntrance = (params, successCallback, errorCallback) => {
  const url = `${host}/graphql/cash-desk?query=query ($credentials: CustomerCredentialsInputType) {
    checkAuthenticationCode(credentials: $credentials)
  }
  &variables={
    "credentials": {
      "phone": "${params.phone}",
      "authenticationCode": "${params.authenticationCode}"
    }
  }`;

  postRequest(url, null, (response) => {
    if (!successCallback) {
      return;
    }

    if (response.errors && response.errors.length) {
      errorCallback(response.errors[0].message);
    } else if (response.data && response.data.checkAuthenticationCode) {
      successCallback();
    } else {
      errorCallback();
    }
  }, (error) => {
    if (errorCallback) {
      errorCallback(error);
    }
  });
};
