import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Keyboard from '../common/keyboard/Keyboard';
import Dot from '../common/Dot';
import { defaultStyles } from "../../settings";
import Header from './Header/Header';
import Points from './Header/Points';

const styles = theme => ({
  root: defaultStyles.root,
  container: {...defaultStyles.container},
  dot: {
    color: theme.palette.text.disabled,
    fontSize: 24,
    margin: '0 1px',
    width: 13,
  },
  activeDot: {
    color: theme.palette.text.primary,
    fontSize: 24,
    fontWeight: 'bold',
    width: 15,
  },
});

class Component extends React.Component {
  state = { value: '' };

  onPress = (keyboardValue) => {
    this.setState({
      value: keyboardValue,
    });
  };

  onPressEnter = () => {
    this.handleValue();
  };

  handleValue = () => {
    const { purchased, history } = this.props;
    const number = `216200${this.state.value}`;
    const coupon = purchased.find(i => i.number === number);

    if (!coupon || !coupon.name) {
      const subject = 'Купон не найден';
      alert('Проверьте введенные данные', subject);
      return;
    }

    history.push({pathname: 'coupon', state: {
      coupon: {
        ...coupon,
        type: 'typed',
      },
    }});
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <Grid
        className={classes.root}
        container
        direction="row"
        alignItems="stretch"
      >
        <Header title="Ввести купон" backButton="dashboard">
          <Points />
        </Header>
        <Grid
          className={classes.container}
          container
          direction="row"
          alignItems="stretch"
        >
          <Grid
            item
            xs
            container
            direction="column"
            alignItems="stretch"
            justify="center"
          >
            <Grid
              item
              style={{
                textAlign: 'center',
                fontSize: 40,
              }}
            >
              Введите номер купона
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              style={{ paddingTop: 40 }}
            >
              <Grid
                style={{
                  fontWeight: '500',
                  fontSize: 24,
                  marginRight: 15,
                }}
              >
                216 200
              </Grid>

              <Dot
                classes={classes}
                value={value[0]}
              />
              <Dot
                classes={classes}
                value={value[1]}
              />
              <Dot
                classes={classes}
                value={value[2]}
                style={{ marginRight: 14 }}
              />
              <Dot
                classes={classes}
                value={value[3]}
              />
              <Dot
                classes={classes}
                value={value[4]}
              />
              <Dot
                classes={classes}
                value={value[5]}
                style={{ marginRight: 14 }}
              />
              <Dot
                classes={classes}
                value={value[6]}
              />
              <Dot
                classes={classes}
                value={value[7]}
              />
              <Dot
                classes={classes}
                value={value[8]}
                style={{ marginRight: 14 }}
              />
              <Dot
                classes={classes}
                value={value[9]}
              />
              <Dot
                classes={classes}
                value={value[10]}
              />
              <Dot
                classes={classes}
                value={value[11]}
              />
            </Grid>
          </Grid>
          <Keyboard
            value={value}
            onPress={this.onPress}
            onEnter={this.onPressEnter}
            minLength={12}
            maxLength={12}
          />
        </Grid>
      </Grid>
    );
  }
}

Component.propTypes = {
  purchased: PropTypes.objectOf(List),
};

export default connect(state => ({
  purchased: state.user.get('purchased'),
}))(withStyles(styles)(Component));
