import * as type from './actionTypes';


export const appSetIp = ip => ({
  type: type.APP_SET_IP,
  ip,
});

export const appAddLoad = () => ({ type: type.APP_ADD_LOAD });
export const appRemoveLoad = () => ({ type: type.APP_REMOVE_LOAD });


export const operatorReset = () => ({
  type: type.OPERATOR_RESET,
});

export const operatorSetAuthorized = isAuthorized => ({
  type: type.OPERATOR_SET_AUTHORIZED,
  isAuthorized: !!isAuthorized,
});

export const operatorSetStoreId = id => ({
  type: type.OPERATOR_SET_STORE_ID,
  id,
});

export const operatorSetRegister = register => ({
  type: type.OPERATOR_SET_REGISTER,
  register,
});


export const appUserReset = () => ({
  type: type.APP_USER_RESET,
});

export const appSetPhone = phone => ({
  type: type.APP_SET_PHONE,
  phone,
});

export const appSetCardNumber = cardNumber => ({
  type: type.APP_SET_CARD_NUMBER,
  cardNumber,
});

export const appSetCoupon = coupon => ({
  type: type.APP_SET_COUPON,
  coupon,
});


export const userReset = () => ({
  type: type.USER_RESET,
});

export const userSetPhone = phone => ({
  type: type.USER_SET_PHONE,
  phone,
});

export const userSetIsPhoneConfirmed = value => ({
  type: type.USER_SET_IS_PHONE_CONFIRMED,
  value,
});

export const userSetCardNumber = cardNumber => ({
  type: type.USER_SET_CARD_NUMBER,
  cardNumber,
});

export const userSetPoints = points => ({
  type: type.USER_SET_POINTS,
  points,
});

export const userSetPurchased = list => ({
  type: type.USER_SET_PURCHASED,
  list,
});

export const userSetAvailableCoupons = list => ({
  type: type.USER_SET_AVAILABLE_COUPONS,
  list,
});

export const userSetInfo = userInfo => ({
  type: type.USER_SET_INFO,
  userInfo,
});

export const userSetId = ({ userId, userSecretKey }) => ({
  type: type.USER_SET_ID,
  userId,
  userSecretKey,
});

export const apiSetPing = apiPing => ({ type: type.API_SET_PING, apiPing });
