import { Map } from 'immutable';

import {
  OPERATOR_RESET,
  OPERATOR_SET_AUTHORIZED,
  OPERATOR_SET_STORE_ID,
  OPERATOR_SET_REGISTER,

} from '../actionTypes';


const initialState = Map({
  authorized: false,
  storeId: null,
  register: null,
});


export default function (state = initialState, action) {
  switch (action.type) {
    case OPERATOR_RESET: {
      return initialState;
    }

    case OPERATOR_SET_AUTHORIZED: {
      return state.set('authorized', action.isAuthorized);
    }

    case OPERATOR_SET_STORE_ID: {
      return state.set('storeId', action.id);
    }

    case OPERATOR_SET_REGISTER: {
      return state.set('register', action.register);
    }

    default:
      return state;
  }
}
