import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';


const Dot = (props) => {
  const { value, classes, style } = props;

  // const docStyles = {
  //   fontSize: 35,
  // };

  return (
    <Grid
      item
      className={value ? classes.activeDot : classes.dot}
      style={style}
    >
      { typeof value === 'string' ? value : '•' }
    </Grid>
  );
};


Dot.defaultProps = {
  value: false,
  classes: {},
};

Dot.propTypes = {
  // className: PropTypes.string.isRequired,
  classes: PropTypes.object,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

export default Dot;
