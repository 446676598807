import { Map } from 'immutable';

import {
  API_SET_PING,
} from '../actionTypes';

const initialState = Map({
  apiPing: false,
});


export default function (state = initialState, action) {
  switch (action.type) {
    case API_SET_PING: {
      return state.set('apiPing', action.apiPing);
    }

    default:
      return state;
  }
}