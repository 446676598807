import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid/Grid";
import Button from "@material-ui/core/Button/Button";
import Icon from "@material-ui/core/Icon/Icon";


const Component = props => (
  <Button
    style={{
      background: 'green',
      width: '320px',
      borderRadius: 0,
      padding: 0,
      height: 72,
      marginBottom: 70,
      ...props.styles
    }}
    onClick={props.onPress}
  >
    <Grid
      container
      alignItems="center"
      justify="center"
    >
      <Icon
        style={{
          color: 'white',
          fontSize: '42px',
          padding: '15px 10px'
        }}
      >
        {props.toDashBoard ? 'home' : 'face'}
      </Icon>
      <span
        style={{
          fontWeight: '800',
          color: 'white',
        }}
      >

        {props.toDashBoard ? 'ГЛАВНЫЙ ЭКРАН' : 'НОВЫЙ КЛИЕНТ'}
      </span>
      {
        props.counter !== null &&
          <span
            style={{
              color: '#8BC34A',
              width: 30,
              marginLeft: 8,
              fontSize: 16,
            }}
          >
           {props.counter}
          </span>
      }
    </Grid>
  </Button>
);

Component.defaultProps = {
  counter: null,
  toDashBoard: false,
};

Component.propTypes = {
  counter: PropTypes.number,
  onPress: PropTypes.func.isRequired,
  toDashBoard: PropTypes.bool,
};

export default Component;
