import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Keyboard from '../common/keyboard/Keyboard';
import Dot from '../common/Dot';
import { operatorSetAuthorized } from '../../redux/actions';
import { trackEvent, setOperatorAuthorized } from '../../libs';
import { defaultStyles } from "../../settings";


const styles = theme => ({
  root: defaultStyles.root,
  dot: {
    color: theme.palette.text.disabled,
    fontSize: 60,
  },
  activeDot: {
    color: theme.palette.text.primary,
    fontSize: 60,
  },
});

class Component extends React.Component {
  state = { password: '' };

  onPress = (keyboardValue) => {
    this.setState({
      password: keyboardValue,
    }, () => {
      const { password } = this.state;

      if (password.length === 4) {
        this.handlePassword();
      }
    });
  };

  resetPassword = () => {
    this.setState({ password: '' });
  };

  handlePassword = () => {
    const { password } = this.state;

    // @todo Как делать одну авторизацию для всех в веб-версии?
    if (password === '0012') {
      this.authorize();
    } else {
      trackEvent('Wrong Password', password);
      this.resetPassword();
    }
  };

  authorize = () => {
    setOperatorAuthorized(true);
    this.props.operatorSetAuthorized(true);
  };

  render() {
    const { classes } = this.props;
    const { password } = this.state;

    return (
      <Grid
        className={classes.root}
        container
        direction="row"
        alignItems="stretch"
      >
        {/* <StatusBar hidden /> */}
        {/* <ProgressBar addScore={this.state.password.length * (0.57 / 4)} /> */}
        <Grid
          item
          xs
          container
          direction="column"
          alignItems="stretch"
          justify="center"
        >
          <Grid
            item
            style={{
              textAlign: 'center',
              fontSize: 40,
            }}
          >
            Введите пароль
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="center"
            spacing={32}
            wrap="nowrap"
            style={{
              marginTop: 50,
              marginBottom: 50,
            }}
          >
            <Dot classes={classes} value={password.length > 0} />
            <Dot classes={classes} value={password.length > 1} />
            <Dot classes={classes} value={password.length > 2} />
            <Dot classes={classes} value={password.length > 3} />
          </Grid>
        </Grid>
        <Keyboard
          value={password}
          onPress={this.onPress}
          minLength={4}
          maxLength={4}
        />
      </Grid>
    );
  }
}

Component.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.any.isRequired,
    activeDot: PropTypes.any.isRequired,
    dot: PropTypes.any.isRequired,
  }).isRequired,

  operatorSetAuthorized: PropTypes.func.isRequired,
};

export default connect(null, { operatorSetAuthorized })(withStyles(styles)(Component));
