import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Barcode from 'react-barcode';
import {formatPhone, storesWithoutExcludeFromStock, trackEvent} from '../../libs';
import { defaultStyles } from '../../settings';
import NewClientButton from '../common/NewClientButton';
import Grid from "@material-ui/core/Grid/Grid";
import {withStyles} from "@material-ui/core";
import Header from "./Header/Header";
import Points from "./Header/Points";
// import Button from "../../../../mobile/src/Screens/Common/Button";
// import {scale, verticalScale} from "../../../../mobile/src/libs";
import Icon from "@material-ui/core/Icon/Icon";
import Button from "@material-ui/core/Button/Button";
import {excludePurchaseByCardName} from "../../api";
import {sendSMSForPhoneEntrance} from "../../api";
import { excludeFromStockBtn } from "../../settings";

const styles = () => ({
  root: defaultStyles.root,
  container: {
    height: 600,
  },
});

class Component extends React.Component {
  componentDidMount() {
    trackEvent('Earn Bonuses', this.props.cardNumber);
  }

  done = () => {
    this.props.history.push('user');
  };

  excludePurchase = () => {
    const { cardNumber, storeId, loads } = this.props;

    if (cardNumber && !loads) {
      excludePurchaseByCardName({cardNumber, storeId}, () => {
        this.props.history.push({pathname: 'success', state: {
            type: 'excludePurchase',
            source: 'earn-bonuses'
          }});
      }, () => {
        alert('Пожалуйста, попробуйте еще раз', 'Произошла ошибка');
      });
    }
  };

  render() {
    const { classes, storeId } = this.props;

    let isSPB;

    if (this.props.storeId < 23) {
      isSPB = true;
    }

    return (
      <Grid
        className={classes.root}
        container
        direction="row"
        alignItems="stretch"
      >
        <Header
          title="Начисление бонусов"
          backButton="user"
        >
          <Points />
        </Header>
        <Grid
          className={classes.container}
          container
        >
          <Grid
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              width: '100%',
              margin: '100px 0 48px'
            }}
          >
            <Grid
              style={{
                fontWeight: '600',
                color: '#333333',
                fontSize: '45px',
                textAlign: 'center',
                paddingTop: '20px'
              }}
            >
              {this.props.phone && formatPhone(this.props.phone)}
            </Grid>

            {this.props.cardNumber && <Grid
              item
              style={{
                paddingTop: 40,
              }}
            >
              <Barcode value={this.props.cardNumber} />
            </Grid>}
          </Grid>

          <Grid
            style={{
              display: 'flex',
              width: '720px',
              marginBottom: 70,
              justifyContent: 'space-around',
              marginLeft: 'auto',
              marginRight: 'auto',
              alignItems: 'flex-start',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {excludeFromStockBtn && storesWithoutExcludeFromStock.indexOf(storeId) === -1 && <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0 20px 40px',
              }}
            >
              <Button
                style={{
                  alignItems: 'center',
                  background: '#FF5252',
                  justifyContent: 'center',
                  padding: '0 20px',
                  width: '320px',
                  height: 72,
                  cursor: 'pointer',
                  borderRadius: 0,
                  fontSize: 12,

                }}
                onClick={this.excludePurchase}
              >
                <Icon
                  style={{
                    fontSize: 42,
                    color: 'rgba(255, 255, 255, 0.9)',
                  }}
                >
                  highlight_off
                </Icon>
                <Grid
                  style={{
                    fontSize: '13px',
                    fontWeight: '800',
                    color: 'rgba(255, 255, 255, 0.9)',
                    marginLeft: '5px',
                  }}
                >
                  ИСКЛЮЧИТЬ ПОКУПКУ ИЗ АКЦИИ
                </Grid>
              </Button>
            </Grid>}

            <NewClientButton
              styles={{margin: '0 20px 40px'}}
              onPress={this.done}
            />

            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0 20px 40px'
              }}
            >
              <Button
                style={{
                  alignItems: 'center',
                  background: 'green',
                  justifyContent: 'center',
                  padding: '0 20px',
                  width: '320px',
                  height: 72,
                  cursor: 'pointer',
                  borderRadius: 0,
                  fontSize: 14,
                }}
                onClick={() => {
                  const phone = this.props.phone.replace('+', '');

                  if (this.props.loads) {
                    return;
                  }

                  sendSMSForPhoneEntrance({
                    phone,
                  }, () => {
                    this.props.history.push({ pathname: 'enter-phone-confirm', state: { phone, type: 'phone-entrance' }});
                  }, (errorMessage = 'Пожалуйста, попробуйте еще раз') => {
                    alert(errorMessage);
                  });
                }}
              >
                <Icon
                  style={{
                    fontSize: 42,
                    color: 'rgba(255, 255, 255, 0.9)',
                  }}
                >
                  add_shopping_cart
                </Icon>
                <Grid
                  style={{
                    fontWeight: '800',
                    color: 'white',
                  }}
                >
                  СПИСАТЬ БОНУСЫ
                </Grid>
              </Button>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    );
  }
}

Component.defaultProps = {
  phone: null,
  cardNumber: null,
};

Component.propTypes = {
  phone: PropTypes.string,
  cardNumber: PropTypes.string,
  storeId: PropTypes.number,
  loads: PropTypes.number,
};

export default connect(state => ({
  phone: state.user.get('phone'),
  cardNumber: state.user.get('cardNumber'),
  storeId: state.operator.get('storeId'),
  loads: state.app.get('loads'),
}))(withStyles(styles)(Component));
