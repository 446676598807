import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Keyboard from '../common/keyboard/Keyboard';
import Dot from '../common/Dot';
import { defaultStyles } from "../../settings";
import Header from './Header/Header';
import Points from './Header/Points';
import { sendSMSForCardActivation } from "../../api";
import Icon from "@material-ui/core/Icon/Icon";
import Button from "@material-ui/core/Button/Button";
import { trackEvent} from "../../libs";
import {
  confirmSMSForCardActivation,
  confirmSMSForPhoneEntrance,
  getUserByCardNumber,
  sendSMSForPhoneEntrance
} from "../../api";
import { userSetPoints, userSetIsPhoneConfirmed, userSetId } from "../../redux/actions";

const styles = theme => ({
  root: defaultStyles.root,
  container: {...defaultStyles.container},

  dot: {
    // padding: theme.spacing.unit * 2,
    color: theme.palette.text.disabled,
    fontSize: 60,
    width: 53,
    padding: 0,
    textAlign: 'center',
  },
  activeDot: {
    color: theme.palette.text.primary,
    fontSize: 60,
    fontWeight: 'bold',
    width: 53,
    padding: 0,
    textAlign: 'center',
  },
});

const freshCounter = 30;

class EnterPhoneConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.onPress = this.onPress.bind(this);
    this.onPressEnter = this.onPressEnter.bind(this);
    this.handleValue = this.handleValue.bind(this);
    this.countDown = this.countDown.bind(this);
    this.countDownDone = this.countDownDone.bind(this);
    this.resendSMSCode = this.resendSMSCode.bind(this);

    this.state = {
      value: '',
      counter: freshCounter,
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.countDown, 1000);
  }

  componentWillUnmount() {
    this.interval = clearInterval(this.interval);
  }

  onPress(keyboardValue) {
    this.setState({
      value: keyboardValue,
    });
  }

  onPressEnter() {
    this.handleValue();
  }

  countDown() {
    const left = this.state.counter;

    if (left === 0) {
      this.countDownDone();

    } else {
      this.setState({
        counter: left - 1,
      });
    }
  }

  countDownDone() {
    this.interval = clearInterval(this.interval);
    this.setState({ counter: freshCounter });
  }

  resendSMSCode() {
    if (this.state.counter === freshCounter) {
      this.interval = clearInterval(this.interval);
      this.interval = setInterval(this.countDown, 1000);
    }

    const { type, phone } = this.props.location.state;

    if (type === 'card-activation') {
      sendSMSForCardActivation({
        phone,
        cardNumber: this.props.cardNumber,
      }, () => {
        alert('Код был отправлен повторно')
      }, (errorMessage = 'Пожалуйста, попробуйте еще раз') => {
        alert(errorMessage);
      });
    }

    if (type === 'phone-entrance') {
      sendSMSForPhoneEntrance({
        phone,
      }, () => {
        // success
      }, (errorMessage = 'Пожалуйста, попробуйте еще раз') => {
        alert(errorMessage);
      });
    }
  };

  onConfirmSuccess = () => {
    const { cardNumber } = this.props;

    const { type, target } = this.props.location.state;

    if (type === 'card-activation') {
      getUserByCardNumber(cardNumber, (cardResponse) => {
        trackEvent('Success Login via Card', cardNumber);
        this.props.userSetPoints(cardResponse.points);
        this.props.userSetId(cardResponse);
        this.props.history.push({ pathname: 'success', state: { type: 'cardActivatedByPhone' }});
      }, (
        response,
        errorSubject,
        errorMessage = 'Пожалуйста, попробуйте еще раз',
      ) => {
        alert(errorMessage);
      });
    }

    if (type === 'phone-entrance') {
      this.props.userSetIsPhoneConfirmed(true);
      this.props.history.push(target || 'dashboard');
    }
  };

  handleValue() {
    const { cardNumber } = this.props;

    const { phone, type } = this.props.location.state;

    if (type === 'card-activation') {
      confirmSMSForCardActivation({
        phone,
        cardNumber,
        authenticationCode: this.state.value,
      }, () => {
        this.onConfirmSuccess();
      }, (errorMessage = 'Проверьте введенный код') => {
        alert(errorMessage);
      });
    }

    if (type === 'phone-entrance') {
      confirmSMSForPhoneEntrance({
        phone,
        authenticationCode: this.state.value,
      }, () => {
        this.onConfirmSuccess();
      }, (errorMessage = 'Проверьте введенный код') => {
        alert(errorMessage);
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { value, counter } = this.state;

    return (
      <Grid
        className={classes.root}
        container
        direction="row"
        alignItems="stretch"
      >
        <Header title="Актвация карты" backButton="dashboard">
          <Points />
        </Header>
        <Grid
          className={classes.container}
          container
          direction="row"
          alignItems="stretch"
        >
          <Grid
            item
            xs
            container
            direction="column"
            alignItems="stretch"
            justify="center"
          >
            <Grid
              item
              style={{
                textAlign: 'center',
                fontSize: 30,
                matginTop: -30,
              }}
            >
              Введите <strong>код</strong> подтверждения
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="center"
              spacing={32}
              wrap="nowrap"
              style={{
                marginTop: 20,
                marginBottom: 50,
              }}
            >

              <Dot
                classes={classes}
                value={value[0]}
              />
              <Dot
                classes={classes}
                value={value[1]}
              />
              <Dot
                classes={classes}
                value={value[2]}
              />
              <Dot
                classes={classes}
                value={value[3]}
              />
            </Grid>
            <Grid style={{ display: 'flex', justifyContent: 'center', margin: '20px 0 -145px'}}>
              <Button
                style={{
                  background: 'green',
                  width: '320px',
                  borderRadius: 0,
                  padding: 0,
                  height: 72,
                }}
                onClick={() => {
                  this.resendSMSCode();
                }}
                disabled={this.props.loads > 0 || counter !== null && counter !== 30}
              >
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                >
                  <Icon
                    style={{
                      color: 'white',
                      fontSize: '42px',
                      padding: '15px 10px'
                    }}
                  >
                    cached
                  </Icon>
                  <span
                    style={{
                      fontWeight: '800',
                      color: 'white',
                    }}
                  >
                    ОТПРАВИТЬ ПОВТОРНО
                  </span>
                  {
                    counter !== null && counter !== 30 &&
                    <span
                      style={{
                        color: '#8BC34A',
                        width: 30,
                        marginLeft: 8,
                        fontSize: 16,
                      }}
                    >
                     {counter}
                    </span>
                  }
                </Grid>
              </Button>
            </Grid>
          </Grid>
          <Keyboard
            value={value}
            onPress={this.onPress}
            onEnter={this.onPressEnter}
            minLength={4}
            maxLength={4}
          />
        </Grid>
      </Grid>
    );
  }
}

EnterPhoneConfirm.propTypes = {
  cardNumber: PropTypes.string.isRequired,
  userSetIsPhoneConfirmed: PropTypes.func,
  userSetPoints: PropTypes.func,
};

export default connect(state => ({
  cardNumber: state.user.get('cardNumber'),
  loads: state.app.get('loads'),
}), {userSetPoints, userSetIsPhoneConfirmed, userSetId})(withStyles(styles)(EnterPhoneConfirm));
