import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Barcode from 'react-barcode';
import Grid from '@material-ui/core/Grid';
import { Map } from 'immutable';
import Button from "@material-ui/core/Button/Button";
import Icon from "@material-ui/core/Icon/Icon";
import {defaultStyles} from "../../settings";
import {trackEvent, formatPoints} from "../../libs";
import {getUser, payCoupon, returnCoupon} from "../../api";
import {appSetCoupon, userSetPoints} from "../../redux/actions";
import Header from "./Header/Header";
import Points from "./Header/Points";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Fade from "@material-ui/core/Fade/Fade";


const styles = () => ({
  root: defaultStyles.root,
  container: {...defaultStyles.container},
});

class Component extends React.Component {
  componentWillMount() {
    const { location } = this.props;
    const { coupon } = location.state;

    if (!coupon || !coupon.name) {
      const subject = 'Купон не найден';
      alert('Проверьте введенные данные', subject);
      return;
    }
  }

  componentWillUnmount() {
    this.props.appSetCoupon(Map());
  }

  pay = () => {
    const { loads, location, cardNumber, userId, userSecretKey } = this.props;

    const { coupon } = location.state;

    if (loads > 0) {
      return;
    }

    // payCoupon(coupon.id, cardNumber, coupon.mindboxId, () => {
    //   trackEvent('Coupon Has Been Paid', coupon.id);
    //   this.props.history.push({pathname: 'success', state: {
    //     type: 'bought',
    //   }});
    // }, () => {
    //   trackEvent('Coupon Payment Error', coupon.id);
    //   alert('Пожалуйста, повторите попытку еще раз', 'Купон не погашен');
    //
    //   this.props.history.push({pathname: 'pay-coupon', state: {
    //     coupon: {
    //       number: this.props.coupon.get('number'),
    //       type: 'bought',
    //     },
    //   }});
    // });

    getUser({
      discountCard:cardNumber,
      successCallback: (response) => {
        payCoupon({
          mindboxId: coupon.mindboxId,
          promoCodeValue: coupon.id,
          userId: response.userData.userId,
          userSecretKey: response.userData.secretKey,
          successCallback: () => {
            trackEvent('Coupon Has Been Paid', coupon.id);
            this.props.history.push({pathname: 'success', state: {
                type: 'bought',
              }});
          },
          errorCallback: () => {
            trackEvent('Coupon Payment Error', coupon.id);
            alert('Пожалуйста, повторите попытку еще раз', 'Купон не погашен');

            this.props.history.push({pathname: 'pay-coupon', state: {
                coupon: {
                  number: this.props.coupon.get('number'),
                  type: 'bought',
                },
              }});
          }
        })
      }
    })

  };

  returnCoupon = () => {
    const { loads, location, cardNumber, userId, userSecretKey } = this.props;

    const { coupon } = location.state;

    if (loads > 0) {
      return;
    }


    getUser({
      discountCard: cardNumber,
      successCallback: (response) => {
        returnCoupon({
          userId: response.userData.userId,
          userSecretKey: response.userData.secretKey,
          mindboxId: coupon.mindboxId,
          balanceCallback: (response) => {
            this.props.userSetPoints(response.available);
          },
          successCallback: (response) => {
            trackEvent('Coupon Has Been Returned', coupon.mindboxId);

            this.props.history.push({pathname: 'success', state: {
                type: coupon.type === 'buy_first' ? 'returnedFromAvailableCoupons' :
                  'returnedFromUserCoupons',
              }});
          },
          errorCallback: () => {
            trackEvent('Coupon Return Error', coupon.mindboxId);
            alert('Пожалуйста, повторите попытку еще раз', 'Купон не возвращен');

            this.props.history.push({pathname: 'pay-coupon', state: {
                coupon: {
                  number: this.props.coupon.get('number'),
                  type: 'returned',
                },
              }});
          }
        })
      }
    })

  };

  goto(where) {
    this.props.history.push(where);
  }

  render() {
    const { classes, coupon, loads, location } = this.props;

    let min = coupon.get('min');
    if (!min) {
      min = coupon.get('points') * 2;
    }

    // if (!location.state.coupon) {
    //   return null;
    // }
    const initType = location.state.coupon.type;
    const isPressable = loads === 0;

    // if (!coupon.get('number')) {
    //   return null;
    // }

    return (
      <Grid
        className={classes.root}
        container
        direction="column"
        alignItems="center"
      >
        <Header
          title="Погасить купон"
          backButton={initType === 'buy_first' ? 'available-coupons' : initType === 'typed' ? 'dashboard' : 'user-coupons'}
        >
          <Points />
        </Header>

        <Fade in={coupon.get('number')}>
          <Grid
            className={classes.container}
            container
            direction="column"
            alignItems="center"
          >
            <Grid
              item
              style={{
                fontSize: 30,
                fontWeight: '600',
                color: '#333333',
                paddingTop: 45,
              }}
            >
              Скидка {coupon.get('points')} <i className="rouble"/>
            </Grid>

            {initType !== 'online' && <Grid
              item
              style={{
                paddingTop: 40,
              }}
            >
              <Barcode value={coupon.get('number')} />
            </Grid>}

            {initType === 'Online' && <Grid
              item
              style={{
                paddingTop: 30,
                fontSize: 24,
                fontWeight: '700',
                color: '#000',
              }}
            >
              Онлайн купон
            </Grid>}

            <Grid
              item
              style={{
                paddingTop: 30,
                fontSize: 24,
                fontWeight: '700',
                color: '#000',
              }}
            >
              Пробейте все товары и примените купон к чеку перед гашением
            </Grid>

            <Grid
              item
              style={{
                paddingTop: 20,
                fontSize: 24,
                fontWeight: '400',
                color: '#000',
              }}
            >
              сумма покупки <span style={{ fontSize: 30, paddingLeft: 3 }}>&ge;{formatPoints(min)} <i className="rouble"/></span>
            </Grid>

            <Grid
              container
              justify="center"
              style={{
                marginTop: 'auto',
                paddingBottom: 45,
              }}
            >
              <Button
                style={{
                  background: '#4CAF50',
                  borderRadius: 0,
                  color: isPressable ? 'rgba(255, 255, 255, 0.9)' : '#72da76',
                  height: 70,
                  width: 320,
                  fontSize: 16,
                  font: 'unset',
                  margin: '0 20px',
                }}
                onClick={this.pay}
              >
                {isPressable ?
                  <Icon size={42} style={{ padding: '0 10px', fontSize: 42, }}>account_balance_wallet</Icon> :
                  <CircularProgress size={42} style={{ color: '#72da76', fontSize: 42, margin: '0 10px'}} />
                }
                <span style={{ fontWeight: '800' }}>Погасить купон</span>
              </Button>

              <Button
                disabled={!isPressable}
                style={{
                  background: '#FF5252',
                  borderRadius: 0,
                  color: isPressable ? 'rgba(255, 255, 255, 0.9)' : '#fd7474',
                  height: 70,
                  width: 320,
                  fontSize: 16,
                  font: 'unset',
                  margin: '0 20px',
                }}
                onClick={this.returnCoupon}
              >
                {isPressable ?
                  <Icon size={42} style={{ padding: '0 10px', fontSize: 42, }}>assignment_return</Icon> :
                  <CircularProgress size={42} style={{ color: '#fd7474', fontSize: 42, margin: '0 10px'}} />
                }
                <span style={{ fontWeight: '800' }}>{initType === 'buy_first' ? 'ОТМЕНА' : 'ВЕРНУТЬ КУПОН'}</span>
              </Button>
            </Grid>
          </Grid>
        </Fade>
      </Grid>
    );
  }
}

Component.propTypes = {
  loads: PropTypes.number,
  cardNumber: PropTypes.string.isRequired,
  coupon: PropTypes.objectOf(Map).isRequired,
  appSetCoupon: PropTypes.func,
};

export default connect(state => ({
  loads: state.app.get('loads'),
  cardNumber: state.user.get('cardNumber'),
  coupon: state.app.get('coupon'),
  userSecretKey: state.user.get('userSecretKey'),
  userId: state.user.get('userId'),
}), { appSetCoupon, userSetPoints })(withStyles(styles)(Component));
